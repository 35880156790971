<template>
    <div class="flex min-h-screen/minus-240 flex-col">
        <div class="mb-36">
        <ManagerNavbar/>
        </div>
        <div class="flex flex-col items-center space-y-10">
            <div class="mx-auto">
                <p class="text-gold text-2xl font-poppins font-bold">Upload CSV</p>
                <div class="flex flex-row space-x-2 items-center mt-4 mb-2">
                    <div class="relative">
                        <select v-model="selectedCSVOption" class="block appearance-none text w-[350px] font-poppins bg-white border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded-md leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option selected disabled hidden> {{selectedCSVOption}} </option>
                            <option v-for="option in csvOptions" :key="option">{{option}}</option>
                        </select>
                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M5.293 7.293L10 12l4.707-4.707L16.414 8l-6 6-6-6 1.414-1.414z"/>
                            </svg>
                        </div>
                    </div>

                    <div style="display: none;">
                        <input type="file" ref="fileInputCSV" @change="fileChosenCSV" :accept="fileTypeAcceptCSV">
                    </div>

                    <div>
                        <button 
                            @click="uploadCSVClicked" 
                            class="bg-gradient-to-r from-light-blue-gradient via-dark-blue-gradient to-dark-blue-gradient font-poppins hover:bg-blue-700 text-white w-[130px] py-2 px-4 rounded-md">
                            {{ buttonTextCSV }}
                        </button>
                    </div>
                </div>
                <div class="flex flex-row items-center space-x-2">
                    <img id="statusImage" v-show="csvUploadStatus !== ''" class="h-8" :src="images[csvUploadStatus]">
                    <p id="uploadStatus" class="font-poppins font-bold" :class="csvUploadStatusClass">{{ csvUploadStatusMessage }}</p>
                </div>
                <p class="text-red-500 font-poppins mb-2" :style="{ 'display': fileTypeErrorMessageCSV }">Please select a file type</p>
            </div>
            <div class="mx-auto">
                <p class="text-gold text-2xl font-poppins font-bold">Upload PDF</p>
                <div class="flex flex-row space-x-2 items-center mt-4 mb-2">
                    <div class="relative">
                    <select v-model="selectedPDFOption" class="block appearance-none text w-[350px] font-poppins bg-white border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded-md leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                        <option selected disabled hidden>{{selectedPDFOption}}</option>
                        <option v-for="option in pdfOptions" :key="option">{{option}}</option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M5.293 7.293L10 12l4.707-4.707L16.414 8l-6 6-6-6 1.414-1.414z"/>
                        </svg>
                    </div>
                    </div>

                    <div style="display: none;">
                    <input type="file" ref="fileInputPDF" @change="fileChosenPDF" :accept="fileTypeAcceptPDF">
                    </div>

                    <div>
                    <button 
                        @click="uploadPDFClicked" 
                        class="bg-gradient-to-r from-light-blue-gradient via-dark-blue-gradient to-dark-blue-gradient font-poppins hover:bg-blue-700 text-white w-[130px] py-2 px-4 rounded-md">
                        {{ buttonTextPDF }}
                    </button>
                    </div>
                </div>
                <div class="flex flex-row items-center space-x-2">
                    <img id="statusImage" v-show="pdfUploadStatus !== ''" class="h-8" :src="images[pdfUploadStatus]">
                    <p id="uploadStatus" class="font-poppins font-bold" :class="pdfUploadStatusClass">{{ pdfUploadStatusMessage }}</p>
                </div>
                <p class="text-red-500 font-poppins mb-2" :style="{ 'display': fileTypeErrorMessagePDF }">Please select a file type</p>
            </div>

            <div class="flex justify-center items-center space-x-10">
                <!-- Upload JPEG 1 -->
                <div>
                    <p class="text-gold text-2xl font-poppins font-bold text-center">Protected Note (JPEG 1)</p>
                    <div class="flex flex-row space-x-2 items-center mt-4 mb-2">
                        <div style="display: none;">
                            <input type="file" ref="fileInputJPEG1" @change="fileChosenJPEG1" accept="image/jpeg">
                        </div>
                        <button 
                            v-if="!fileSelectedJPEG1" 
                            @click="$refs.fileInputJPEG1.click()" 
                            class="bg-gradient-to-r from-light-blue-gradient via-dark-blue-gradient to-dark-blue-gradient font-poppins hover:bg-blue-700 text-white w-[130px] py-2 px-4 rounded-md">
                            {{ buttonTextJPEG1 }}
                        </button>
                        <button 
                            v-if="fileSelectedJPEG1" 
                            @click="uploadJPEG1Clicked" 
                            class="bg-gradient-to-r from-light-blue-gradient via-dark-blue-gradient to-dark-blue-gradient font-poppins hover:bg-blue-700 text-white w-[130px] py-2 px-4 rounded-md">
                            Upload
                        </button>
                    </div>

                    <div class="flex flex-row items-center space-x-2">
                        <img v-show="jpeg1UploadStatus !== ''" class="h-8" :src="images[jpeg1UploadStatus]" />
                        <p v-show="jpeg1UploadStatus !== ''" class="font-poppins font-bold" :class="jpeg1UploadStatusClass">
                            {{ jpeg1UploadStatusMessage }}
                        </p>
                    </div>
            </div>

            <!-- Upload JPEG 2 -->
            <div>
                <p class="text-gold text-2xl font-poppins font-bold text-center">Autocall Note (JPEG 2)</p>
                <div class="flex flex-row space-x-2 items-center mt-4 mb-2">
                <div style="display: none;">
                    <input type="file" ref="fileInputJPEG2" @change="fileChosenJPEG2" accept="image/jpeg">
                </div>
                <button 
                    v-if="!fileSelectedJPEG2" 
                    @click="$refs.fileInputJPEG2.click()" 
                    class="bg-gradient-to-r from-light-blue-gradient via-dark-blue-gradient to-dark-blue-gradient font-poppins hover:bg-blue-700 text-white w-[130px] py-2 px-4 rounded-md">
                    {{ buttonTextJPEG2 }}
                </button>
                <button 
                    v-if="fileSelectedJPEG2" 
                    @click="uploadJPEG2Clicked" 
                    class="bg-gradient-to-r from-light-blue-gradient via-dark-blue-gradient to-dark-blue-gradient font-poppins hover:bg-blue-700 text-white w-[130px] py-2 px-4 rounded-md">
                    Upload
                </button>
            </div>

            <div class="flex flex-row items-center space-x-2">
                <img v-show="jpeg2UploadStatus !== ''" class="h-8" :src="images[jpeg2UploadStatus]" />
                <p v-show="jpeg2UploadStatus !== ''" class="font-poppins font-bold" :class="jpeg2UploadStatusClass">
                    {{ jpeg2UploadStatusMessage }}
                </p>
            </div>
            </div>
        </div>

        </div>
    </div>
    <button @click="navigateManagerAdmin()" class="bg-gradient-to-r from-dark-blue-gradient via-dark-blue-gradient to-light-blue-gradient rounded-md font-poppins text-white px-4 py-2 mt-12 mb-12 ml-6">
        <span class="flex items-center">
            <svg class="fill-white mr-3" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
            Manager List
        </span>
    </button>
    <Footer/>
</template>

<script>
import ManagerNavbar from '../components/Atomic/AQuarks/ManagerNavbar.vue';
import Footer from '../components/Atomic/AQuarks/Footer.vue';
import { ref } from 'vue'
import axiosConfig from '../axiosConfig';
import axios from 'axios';
import MainLayout from '../components/Layouts/MainLayout.vue';


export default {
    name: 'ManagerAdmin',
    components: {
      ManagerNavbar,
      Footer,
      MainLayout
    },    
    methods: {
        fileChosenJPEG1(event) {
            if (event.target.files.length > 0) {
                this.jpeg1File = event.target.files[0];
                this.fileSelectedJPEG1 = true;
                this.buttonTextJPEG1 = "Upload";
            }
        },

        fileChosenJPEG2(event) {
            if (event.target.files.length > 0) {
                this.jpeg2File = event.target.files[0];
                this.fileSelectedJPEG2 = true;
                this.buttonTextJPEG2 = "Upload";
            }
        },

        async uploadJPEG1Clicked() {
            if (!this.jpeg1File) return;
            if (!this.jpeg1File) return;

            if (!this.jpeg1File) return;

            this.jpeg1UploadStatus = "loading";

            try {
                // Use a distinct ISIN for JPEG1
                // const response = await axiosConfig.post(`/File?fileType=JPEG&isin=ZAE000180212`);
                const response = await axiosConfig.post(`/File?fileType=JPEG&isin=image1`);
                const uploadUrl = response.data; // Ensure correct URL format

                if (!uploadUrl) throw new Error("No upload URL received");

                await axios.put(uploadUrl, this.jpeg1File, {
                    headers: { "Content-Type": this.jpeg1File.type }
                });

                this.jpeg1UploadStatus = "success";
                this.uploadedJPEG1Url = uploadUrl.split("?")[0];
            } catch (error) {
                console.error("Error uploading JPEG 1:", error);
                this.jpeg1UploadStatus = "fail";
            }
        },

        async uploadJPEG2Clicked() {
            if (!this.jpeg2File) return;
            this.jpeg2UploadStatus = "loading";

            try {
                // Use a distinct ISIN for JPEG2
                // const response = await axiosConfig.post(`/File?fileType=JPEG&isin=ZAE000180213`);
                const response = await axiosConfig.post(`/File?fileType=JPEG&isin=image2`);
                const uploadUrl = response.data; // Ensure correct URL format

                if (!uploadUrl) throw new Error("No upload URL received");

                await axios.put(uploadUrl, this.jpeg2File, {
                    headers: { "Content-Type": this.jpeg2File.type }
                });

                this.jpeg2UploadStatus = "success";
                this.uploadedJPEG2Url = uploadUrl.split("?")[0];
            } catch (error) {
                console.error("Error uploading JPEG 2:", error);
                this.jpeg2UploadStatus = "fail";
            }
        },

        async uploadCSVClicked() {
            if (this.selectedCSVOption === 'Upload File') {
                this.fileTypeErrorMessageCSV = 'block';
                this.csvUploadStatus = '';
                return;
            }else{
                this.csvUploadStatus = '';
                this.fileTypeErrorMessageCSV = 'none';
            }
            if(!this.fileSelectedCSV) {
                this.fileInputCSV.click();
            } else {
                this.csvUploadStatus = 'loading';                
                let formData = new FormData();
                formData.append('file', this.$refs.fileInputCSV.files[0]);
                let fileTypeKey = this.selectedCSVOption;
                let fileType = this.csvOptionsMap[fileTypeKey]
                let url = `/File?fileType=${fileType}`
                const uploadResponse = await axiosConfig.post(url, formData)
                .then((response) => {                    
                    this.csvUploadStatus = 'success';
                    this.$refs.fileInputCSV.value = '';
                    this.fileSelectedCSV = false
                    this.selectedCSVOption = 'Upload File'
                    this.buttonTextCSV = 'Choose File'
                }).catch((error) => {
                    this.csvUploadStatus = 'fail';
                    this.$refs.fileInputCSV.value = '';
                    this.fileSelectedCSV = false
                    this.selectedCSVOption = 'Upload File'
                    this.buttonTextCSV = 'Choose File'
                });
            }
        },
        fileChosenCSV(event) {
            if (event.target.files.length > 0) {
            this.csvFile = event.target.files[0];
            this.fileSelectedCSV = true;
            this.buttonTextCSV = 'Upload';
            }
        },
        async uploadPDFClicked() {
            if (this.selectedPDFOption === 'Upload File') {
                this.fileTypeErrorMessagePDF = 'block';
                this.pdfUploadStatus = '';
                return;
            }else{
                this.fileTypeErrorMessagePDF = 'none';
                this.pdfUploadStatus = '';
            }
            if(!this.fileSelectedPDF) {
                this.fileInputPDF.click();
            } else {
                this.pdfUploadStatus = 'loading';                
                let file = this.$refs.fileInputPDF.files[0];                
                let fileTypeKey = this.selectedPDFOption;
                let fileIsin = this.pdfOptionsMap[fileTypeKey]
                let axiosBaseURI = process.env.VUE_APP_API_BASEURL;              

                let signedUrl = await this.getSignedUrl(fileIsin)
                .catch((error) => {
                    this.pdfUploadStatus = 'fail';
                    this.$refs.fileInputPDF.value = '';
                    this.fileSelectedPDF = false
                    this.selectedPDFOption = 'Upload File'
                    this.buttonTextPDF = 'Choose File'
                });
                let uploadStatus = await this.uploadToS3(signedUrl, file)
                .then((response) => {
                    this.pdfUploadStatus = 'success';
                    this.$refs.fileInputPDF.value = '';
                    this.fileSelectedPDF = false
                    this.selectedPDFOption = 'Upload File'
                    this.buttonTextPDF = 'Choose File'
                }).catch((error) => {
                    this.pdfUploadStatus = 'fail';
                    this.$refs.fileInputPDF.value = '';
                    this.fileSelectedPDF = false
                    this.selectedPDFOption = 'Upload File'
                    this.buttonTextPDF = 'Choose File'
                });
            }
        },

        async getSignedUrl(fileIsin) {
            let url = `/File?fileType=pdf&isin=${fileIsin}`
            const uploadResponse = await axiosConfig.post(url)
            return uploadResponse;
        },
        async uploadToS3(signedUrl, file) {
            let axiosBaseURI = process.env.VUE_APP_API_BASEURL;
            const options = {
                headers: {
                    'Content-Type': file.type,
                },
            };
            try{
                await axios.put(signedUrl.data, file, options);
            }catch(error){
                console.error(error)
            }
        },

        fileChosenPDF(event) {
        if (event.target.files.length > 0) {
          this.pdfFile = event.target.files[0];
          this.fileSelectedPDF = true;
          this.buttonTextPDF = 'Upload';
        }
    },
    navigateManagerAdmin() {
        this.$router.push(`/admin/manager`).then(() => {
            window.scrollTo(0, 0);
        });
    }
        
    },
    data() {
        return{
            csvOptions: ['TER Monthly', 'USD Monthly', 'ZAR Monthly', 'USD Daily Returns', 'ZAR Daily Returns', 'Stock Market Returns'],
            csvOptionsMap: {
                'TER Monthly': 'termonthly',
                'USD Monthly': 'usdmonthly',
                'ZAR Monthly': 'zarmonthly',
                'USD Daily Returns': 'usddaily',
                'ZAR Daily Returns': 'zardaily',
                'Stock Market Returns': 'stockmarket'
            },
            pdfOptions: ['SA Solutions (ZAR)','Global Solutions (USD)','Manager Report', 'About Us' , 'Investment Approach', 'Recent Trades', 'Market Outlook', 'Market Insights', 'Income Solution Fact Sheet', 'Cautious Solution Fact Sheet', 
                            'Moderate Solution Fact Sheet', 'Balanced Solution Fact Sheet', 'Flexible Solution Fact Sheet', 'Growth Solution Fact Sheet', 'TFSA Solution Fact Sheet', 'Multi-Hedge Solution Fact Sheet', 'Global Feeder Solution Fact Sheet', 'Enhanced Balanced Solution Fact Sheet', 'Enhanced Low Income Fact Sheet', 'Enhanced Medium Income Fact Sheet', 'Enhanced High Income Fact Sheet',
                            'Global Defensive Income Solution Fact Sheet', 'Global Cautious Solution Fact Sheet', 'Global Cautious Passive Solution Fact Sheet', 'Global Moderate Solution Fact Sheet', 'Global Balanced Solution Fact Sheet', 'Global Balanced Passive Solution Fact Sheet', 'Global Growth Solution Fact Sheet', 'Global Liquid Alts Solution Fact Sheet', 'Enhanced Protector Fact Sheet', 'Enhanced Accumulator Fact Sheet', 'Protected Note (JPEG 1)', 'Autocall Note (JPEG 2)'],
            pdfOptionsMap : {
                'SA Solutions (ZAR)' : 'ZASOL',
                'Global Solutions (USD)' : 'USSOL',
                'Manager Report' : 'MGRREP',
                'About Us': 'ABOUT',
                'Investment Approach': 'APPROACH',
                'Recent Trades' : 'RECTRA',
                'Market Outlook': 'OUTLOOK',
                'Market Insights': 'INSIGHTS',
                // SA Solution Fact Sheet
                'Income Solution Fact Sheet': 'IQ2065',
                'Cautious Solution Fact Sheet': 'IQ2066',
                'Moderate Solution Fact Sheet': 'IQMOD',
                'Balanced Solution Fact Sheet': 'IQ2064',
                'Flexible Solution Fact Sheet': 'IQ2809',
                'Growth Solution Fact Sheet': 'IQ2186',
                'TFSA Solution Fact Sheet': 'IQ2578',
                'Mutli-Hedge Solution Fact Sheet': 'IQ2593',
                'Global Feeder Solution Fact Sheet': 'IQ2158',
                'Enhanced Balanced Solution Fact Sheet': 'IQ3102', 
                'Enhanced Low Income Fact Sheet': 'IQLAL', 
                'Enhanced Medium Income Fact Sheet': 'IQLAM', 
                'Enhanced High Income Fact Sheet': 'IQLAH',
                // Global Solution Facts Sheet
                'Global Defensive Income Solution Fact Sheet': 'IQ910',
                'Global Cautious Solution Fact Sheet': 'IQ2643',
                'Global Cautious Passive Solution Fact Sheet': 'IQ937',
                'Global Moderate Solution Fact Sheet': 'IQGMOD',
                'Global Balanced Solution Fact Sheet': 'IQ2595',
                'Global Balanced Passive Solution Fact Sheet': 'IQGBAL',
                'Global Growth Solution Fact Sheet': 'IQ2581',
                'Global Liquid Alts Solution Fact Sheet': 'IQ955',
                'Enhanced Protector Fact Sheet': 'IQGPRO',
                'Enhanced Accumulator Fact Sheet': 'IQGACC',

                // JPEG1 & JPEG2 PDF Uploads
                'Protected Note (JPEG 1)': 'PROTECTEDNOTE',
                'Autocall Note (JPEG 2)': 'AUTOCALLNOTE'
            },
            buttonTextCSV: 'Choose File',
            buttonTextPDF: 'Choose File',
            selectedCSVOption: 'Upload File',
            selectedPDFOption: 'Upload File',
            csvFile: null,
            pdfFile: null,
            fileSelectedCSV: false,
            fileSelectedPDF: false,
            fileTypeAcceptCSV: '.csv',
            fileTypeAcceptPDF: '.pdf',
            fileTypeErrorMessageCSV: 'none',
            fileTypeErrorMessagePDF: 'none',
            csvUploadStatus: '', // 'loading', 'success', 'fail'
            pdfUploadStatus: '', // 'loading', 'success', 'fail'
            // JPEG 1
            jpeg1File: null,
            fileSelectedJPEG1: false,
            buttonTextJPEG1: "Choose File",
            jpeg1UploadStatus: "",
            uploadedJPEG1Url: "",
            // JPEG 2
            jpeg2File: null,
            fileSelectedJPEG2: false,
            buttonTextJPEG2: "Choose File",
            jpeg2UploadStatus: "",
            uploadedJPEG2Url: "",
            images: {
                loading: '/img/loading.gif',
                success: '/img/checkmark.svg',
                fail: '/img/cancel.svg'
            }

        }
    },
    computed: {
    csvUploadStatusMessage() {
        switch(this.csvUploadStatus) {
            case 'loading': return 'Uploading...';
            case 'success': return 'Upload Successful!';
            case 'fail': return 'Upload Failed.';
            default: return '';
        }
    },
    csvUploadStatusClass() {
        switch(this.csvUploadStatus) {
            case 'loading': return 'text-dark-blue';
            case 'success': return 'text-[#5FB763]';
            case 'fail': return 'text-[#C52126]';
            default: return '';
        }
    },
    pdfUploadStatusMessage() {
        switch(this.pdfUploadStatus) {
            case 'loading': return 'Uploading...';
            case 'success': return 'Upload Successful!';
            case 'fail': return 'Upload Failed.';
            default: return '';
        }
    },
    pdfUploadStatusClass() {
        switch(this.pdfUploadStatus) {
            case 'loading': return 'text-dark-blue';
            case 'success': return 'text-[#5FB763]';
            case 'fail': return 'text-[#C52126]';
            default: return '';
        }
    },

    pngUploadStatusMessage() {
            switch (this.pngUploadStatus) {
                case 'loading': return 'Uploading...';
                case 'success': return 'Upload Successful!';
                case 'fail': return 'Upload Failed.';
                default: return '';
            }
        },
    pngUploadStatusClass() {
        switch (this.pngUploadStatus) {
            case 'loading': return 'text-dark-blue';
            case 'success': return 'text-[#5FB763]';
            case 'fail': return 'text-[#C52126]';
            default: return '';
        }
    },


    jpeg1UploadStatusMessage() {
        switch (this.jpeg1UploadStatus) {
            case 'loading': return 'Uploading...';
            case 'success': return 'Upload Successful!';
            case 'fail': return 'Upload Failed.';
            default: return '';
        }
    },
    jpeg2UploadStatusMessage() {
        switch (this.jpeg2UploadStatus) {
            case 'loading': return 'Uploading...';
            case 'success': return 'Upload Successful!';
            case 'fail': return 'Upload Failed.';
            default: return '';
        }
    },
    jpeg1UploadStatusClass() {
        switch (this.jpeg1UploadStatus) {
            case 'loading': return 'text-dark-blue';
            case 'success': return 'text-[#5FB763]';
            case 'fail': return 'text-[#C52126]';
            default: return '';
        }
    },
    jpeg2UploadStatusClass() {
        switch (this.jpeg2UploadStatus) {
            case 'loading': return 'text-dark-blue';
            case 'success': return 'text-[#5FB763]';
            case 'fail': return 'text-[#C52126]';
            default: return '';
        }
    },
    
},
    mounted() {
      this.fileInputCSV = this.$refs.fileInputCSV;
      this.fileInputPDF = this.$refs.fileInputPDF;
    },

}

</script>