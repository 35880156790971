import { createApp } from 'vue'
import App from './App.vue'
import router, { setupAuthGuard } from './router'
import VueApexCharts from "vue3-apexcharts";
import keycloak from './keycloak';
import '../public/css/tailwind.css'
import '@/assets/styles/index.css'

keycloak.init({ onLoad: 'login-required' }).then((authenticated) => {

    console.log("Starting authentication");
    if (authenticated) {
        const app = createApp(App);
        app.config.globalProperties.$keycloak = keycloak;
        app.use(router)
        app.use(VueApexCharts)
        app.component('apexchart', VueApexCharts)
        app.mount('#app');
    } else {
      console.warn('Failed to authenticate');
    }
  }).catch((err) => {
    console.error('Keycloak initialization failed', err);
  });