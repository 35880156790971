<template>
  <MainLayout>
  <NavBar />
  <div class="flex flex-col flex-1">
    <div class="container min-w-full">
      <h1 class="font-poppins text-3xl text-dark-blue font-bold mt-6 ml-5 md:mt-6 lg:mt-10">
        {{ solutionDataMonthly && solutionDataMonthly.length > 0 && solutionDataMonthly[0].row ? solutionDataMonthly[0].row.Description : 'No data' }}
      </h1>
      <div class="flex font-poppins mt-4 text-dark-blue">
        <div class="w-1/2 ml-5">
          <h4 class="font-poppins font-bold text-dark-blue text-base">Investment Objective</h4>
          <p class="font-poppins font-medium text-dark-blue text-sm mt-1 leading-normal">
            {{ solutionDescription.objectiveDescription }}
          </p>
        </div>
        <div class="w-1/2 ml-4">
          <h4 class="font-poppins font-bold text-dark-blue text-base">Investment Strategy</h4>
          <p class="font-poppins font-medium text-dark-blue text-sm mt-1 leading-normal">
            {{ solutionDescription.strategyDescription }}
          </p>
        </div>
      </div>

        <div>
          <h3 v-if="errorMessage" class="font-poppins text-center font-extrabold text-gold mt-14">No data is available at the moment.</h3>
        </div>
        
        <h3  v-if="!errorMessage" class="font-poppins font-extrabold text-gold ml-6 mt-4">Performance Summary</h3>
        <div class="flex stacked-sections">
          <div class="w-1/2 chart-container">
            <div class="w-[97%] mt-4 ml-2">
              <div v-if="!errorMessage" id="chart1">
                <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
              </div>
              <table v-if="!errorMessage" class="font-poppins font-semibold text-xs text-center mt-9 mx-auto">
                <thead class="text-xs uppercase bg-dark-blue text-white">
                  <tr>
                    <th scope="col" class="px-6 py-3">Performance</th>
                    <th scope="col" class="px-6 py-3">Inception</th>
                    <th scope="col" class="px-6 py-3">1 Month</th>
                    <th scope="col" class="px-6 py-3">3 Month</th>
                    <th scope="col" class="px-6 py-3">6 Month</th>
                    <th scope="col" class="px-6 py-3">1 Year</th>
                    <th scope="col" class="px-6 py-3">3 Year</th>
                    <th scope="col" class="px-6 py-3">5 Year</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white text-dark-blue">
                    <td class="px-6 py-3">
                      {{ solutionDataDaily && solutionDataDaily[0]?.row?.Description || 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ solutionDataDaily && solutionDataDaily[0]?.row?.SinceInception || 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ solutionDataDaily && solutionDataDaily[0]?.row?.Month1 || 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ solutionDataDaily && solutionDataDaily[0]?.row?.Month3 || 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ solutionDataDaily && solutionDataDaily[0]?.row?.Month6 || 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ solutionDataDaily && solutionDataDaily[0]?.row?.Year1 || 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ solutionDataDaily && solutionDataDaily[0]?.row?.Year3 || 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ solutionDataDaily && solutionDataDaily[0]?.row?.Year5 || 'No data' }}
                    </td>
                  </tr>

                  <!-- Benchmark Data -->
                  <tr class="bg-white border-b-2 border-dark-blue text-dark-blue">
                    <td class="px-6 py-3">
                      {{ benchmarkDataDaily && benchmarkDataDaily[0]?.row?.Description || 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ benchmarkDataDaily && benchmarkDataDaily[0]?.row?.SinceInception || 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ benchmarkDataDaily && benchmarkDataDaily[0]?.row?.Month1 || 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ benchmarkDataDaily && benchmarkDataDaily[0]?.row?.Month3 || 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ benchmarkDataDaily && benchmarkDataDaily[0]?.row?.Month6 || 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ benchmarkDataDaily && benchmarkDataDaily[0]?.row?.Year1 || 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ benchmarkDataDaily && benchmarkDataDaily[0]?.row?.Year3 || 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ benchmarkDataDaily && benchmarkDataDaily[0]?.row?.Year5 || 'No data' }}
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>

          <div class="w-1/2 ml-4 chart-container">
            <div class="w-[97%] mt-4">
              <div class="text-center font-poppins font-bold mt-3 text-md text-gold">
                Risk-Return: 5 years
              </div>
              <div class="flex flex-col items-center mb-3" v-if="!errorMessage" id="chart2">
                <apexchart type="scatter" height="350" width="350" :options="scatterChartOptions" :series="scatterSeries"></apexchart>
              </div>
              <table  v-if="!errorMessage" class="font-poppins font-semibold text-xs text-center mx-auto">
                <thead class="text-xs uppercase bg-dark-blue text-white">
                  <tr>
                    <th scope="col" class="px-6 py-3">
                      Investment Name
                    </th>
                    <th scope="col" class="px-6 py-3">
                      5 YR Annualised Return
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Std Dev
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Sharpe Ratio
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Max Drawdown
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white text-dark-blue">
                    <td class="px-6 py-3">
                      {{ solutionDataMonthly && solutionDataMonthly.length > 0 && solutionDataMonthly[0].row ? solutionDataMonthly[0].row.Description : 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ solutionDataMonthly && solutionDataMonthly.length > 0 && solutionDataMonthly[0].row ? solutionDataMonthly[0].row.AnnualisedReturn5Year : 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ solutionDataMonthly && solutionDataMonthly.length > 0 && solutionDataMonthly[0].row ? solutionDataMonthly[0].row.StdDev : 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ solutionDataMonthly && solutionDataMonthly.length > 0 && solutionDataMonthly[0].row ? solutionDataMonthly[0].row.Sharpe : 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ solutionDataMonthly && solutionDataMonthly.length > 0 && solutionDataMonthly[0].row ? solutionDataMonthly[0].row.MaxDrawdown : 'No data' }}
                    </td>
                  </tr>
                  <tr class="bg-white border-b-2 border-dark-blue text-dark-blue">
                    <td class="px-6 py-3">
                      {{ solutionDataMonthly && solutionDataMonthly.length > 0 && solutionDataMonthly[0].row ? solutionDataMonthly[0].row.Benchmark.Description : 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ solutionDataMonthly && solutionDataMonthly.length > 0 && solutionDataMonthly[0].row ? solutionDataMonthly[0].row.Benchmark.AnnualisedReturn5Year : 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ solutionDataMonthly && solutionDataMonthly.length > 0 && solutionDataMonthly[0].row ? solutionDataMonthly[0].row.Benchmark.StdDev : 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ solutionDataMonthly && solutionDataMonthly.length > 0 && solutionDataMonthly[0].row ? solutionDataMonthly[0].row.Benchmark.Sharpe : 'No data' }}
                    </td>
                    <td class="px-6 py-3">
                      {{ solutionDataMonthly && solutionDataMonthly.length > 0 && solutionDataMonthly[0].row ? solutionDataMonthly[0].row.Benchmark.MaxDrawdown : 'No data' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
     </div> 
     <div>
      <div class="my-4">
        <button @click="onClickNavigatePrev()" class="bg-gradient-to-r from-dark-blue-gradient via-dark-blue-gradient to-light-blue-gradient rounded-md font-poppins text-white px-6 py-2 ml-6">
          <span class="flex items-center">
            <svg class="fill-white mr-3" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
            Home
          </span>
        </button>

        <button @click="onClickNavigateNext()" class="bg-gradient-to-r from-light-blue-gradient via-dark-blue-gradient to-dark-blue-gradient rounded-md font-poppins text-white px-4 py-2 ml-6 absolute right-6">
          <span class="flex items-center">
            Sector & Geographical Exposure
            <svg class="fill-white ml-3" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </span> 
        </button> 
      </div>
      
      <Footer /> 
    </div>
  </MainLayout>
</template>

<style scoped>
  @media (max-width: 1600px) {
  .stacked-sections {
    display: flex;
    flex-direction: column;
  }
  .stacked-sections > * {
    margin-bottom: 1.5rem;
  }
  .chart-container {
    width: 98%;
  }
  .description {
    margin-right: 15px;
  }
}
</style>

<script>
  import NavBar from '../components/Atomic/AQuarks/NavBar.vue'; 
  import Footer from '../components/Atomic/AQuarks/Footer.vue';
  import { useRouter } from 'vue-router';
  import axios from '../axiosConfig';
  import MainLayout from '../components/Layouts/MainLayout.vue';

  export default {
    el: '#app',
    name: 'BalancedSolutionPg1',
    components: {
      NavBar,
      Footer,
      MainLayout
    },
    setup() {
      const router = useRouter();

      return {router};
    },
    methods: {
      onClickNavigatePrev() {
        this.$router.push(`/landing`).then(() => {
          window.scrollTo(0, 0);
        });
      },
      onClickNavigateNext() {
        this.$router.push(`/${this.$route.params.ISIN}/${this.$route.params.LOCALE}/2`).then(() => {
          window.scrollTo(0, 0);
        });
      },

    },
    async created() {
      let locale = ''
      if(this.$route.params.LOCALE == 'SA')
      {
        locale = "zar"
      }
      else{
        locale = "usd"
      }
      let ISIN = this.$route.params.ISIN;
      let BenchmarkISIN = "BM" + ISIN.slice(2);
      const dailyResponse = await axios.get(`/File?filetype=${locale}Daily`);
      const monthlyResponse = await axios.get(`/File?filetype=${locale}Monthly`);

      if (!dailyResponse.data || !monthlyResponse.data) {
        this.errorMessage = "No data is available at the moment.";
        return; 
      }

      const parsedDailyData = dailyResponse.data;
      const parsedMonthlyData = monthlyResponse.data;

      let filteredISINDailyData = parsedDailyData.filter(item => item.row?.Isin === ISIN)
      let filteredISINMonthlyData = parsedMonthlyData.filter(item => item.row?.Isin === ISIN)

      let filteredBenchmarkDailyData = parsedDailyData.filter(item => item.row?.Isin === BenchmarkISIN)
      console.log("Benchmark",filteredBenchmarkDailyData)

      this.solutionDataDaily = filteredISINDailyData.length > 0 ? filteredISINDailyData : null;
      this.solutionDataMonthly = filteredISINMonthlyData.length > 0 ? filteredISINMonthlyData : null;
      this.benchmarkDataDaily = filteredBenchmarkDailyData.length > 0 ? filteredBenchmarkDailyData : null;

      if (this.solutionDataDaily && this.solutionDataDaily.length > 0) {
        this.series = [
          {
            name: this.solutionDataDaily[0]?.row?.Description || "No Description",
            data: [
              this.solutionDataDaily[0]?.row?.SinceInception || 0,
              this.solutionDataDaily[0]?.row?.Month1 || 0,
              this.solutionDataDaily[0]?.row?.Month3 || 0,
              this.solutionDataDaily[0]?.row?.Month6 || 0,
              this.solutionDataDaily[0]?.row?.Year1 || 0,
              this.solutionDataDaily[0]?.row?.Year3 || 0,
              this.solutionDataDaily[0]?.row?.Year5 || 0,
            ],
          },
        ];

        if (this.benchmarkDataDaily && this.benchmarkDataDaily.length > 0) {
          this.series.push({
            name: this.benchmarkDataDaily[0]?.row?.Description || "No Description",
            data: [
              this.benchmarkDataDaily[0]?.row?.SinceInception || 0,
              this.benchmarkDataDaily[0]?.row?.Month1 || 0,
              this.benchmarkDataDaily[0]?.row?.Month3 || 0,
              this.benchmarkDataDaily[0]?.row?.Month6 || 0,
              this.benchmarkDataDaily[0]?.row?.Year1 || 0,
              this.benchmarkDataDaily[0]?.row?.Year3 || 0,
              this.benchmarkDataDaily[0]?.row?.Year5 || 0,
            ],
          });
        }
      } else {
        console.error("No solution or benchmark data available");
      }

      if (this.solutionDataMonthly && this.solutionDataMonthly.length > 0) {
        this.scatterSeries = [
            {
                name: this.solutionDataMonthly[0]?.row?.Description || 'Solution Description',
                data: [
                    [
                        this.solutionDataMonthly[0]?.row?.StdDev || 0,
                        this.solutionDataMonthly[0]?.row?.AnnualisedReturn5Year || 0,
                    ],
                ],
            },
            {
                name: this.solutionDataMonthly[0]?.row?.Benchmark?.Description || 'Benchmark Description',
                data: [
                    [
                        this.solutionDataMonthly[0]?.row?.Benchmark?.StdDev || 0,
                        this.solutionDataMonthly[0]?.row?.Benchmark?.AnnualisedReturn5Year || 0,
                    ],
                ],
            },
        ];
      } else {
          this.scatterSeries = [];
      }

      let minMaxBarGraph = Math.max.apply(Math, [
        // Bar Graph(Solution)
        Math.abs(Math.min.apply(Math, this.solutionDataDaily.map(item => [item.row.SinceInception]))),
        Math.abs(Math.max.apply(Math, this.solutionDataDaily.map(item => [item.row.SinceInception]))),
        Math.abs(Math.min.apply(Math, this.solutionDataDaily.map(item => [item.row.Month1]))),
        Math.abs(Math.max.apply(Math, this.solutionDataDaily.map(item => [item.row.Month1]))),
        Math.abs(Math.min.apply(Math, this.solutionDataDaily.map(item => [item.row.Month3]))),
        Math.abs(Math.max.apply(Math, this.solutionDataDaily.map(item => [item.row.Month3]))),
        Math.abs(Math.min.apply(Math, this.solutionDataDaily.map(item => [item.row.Month6]))),
        Math.abs(Math.max.apply(Math, this.solutionDataDaily.map(item => [item.row.Month6]))),
        Math.abs(Math.min.apply(Math, this.solutionDataDaily.map(item => [item.row.Year1]))),
        Math.abs(Math.max.apply(Math, this.solutionDataDaily.map(item => [item.row.Year1]))),
        Math.abs(Math.min.apply(Math, this.solutionDataDaily.map(item => [item.row.Year3]))),
        Math.abs(Math.max.apply(Math, this.solutionDataDaily.map(item => [item.row.Year3]))),
        Math.abs(Math.min.apply(Math, this.solutionDataDaily.map(item => [item.row.Year5]))),
        Math.abs(Math.max.apply(Math, this.solutionDataDaily.map(item => [item.row.Year5]))),

        // Benchmark
        Math.abs(Math.min.apply(Math, this.benchmarkDataDaily.map(item => [item.row.SinceInception]))),
        Math.abs(Math.max.apply(Math, this.benchmarkDataDaily.map(item => [item.row.SinceInception]))),
        Math.abs(Math.min.apply(Math, this.benchmarkDataDaily.map(item => [item.row.Month1]))),
        Math.abs(Math.max.apply(Math, this.benchmarkDataDaily.map(item => [item.row.Month1]))),
        Math.abs(Math.min.apply(Math, this.benchmarkDataDaily.map(item => [item.row.Month3]))),
        Math.abs(Math.max.apply(Math, this.benchmarkDataDaily.map(item => [item.row.Month3]))),
        Math.abs(Math.min.apply(Math, this.benchmarkDataDaily.map(item => [item.row.Month6]))),
        Math.abs(Math.max.apply(Math, this.benchmarkDataDaily.map(item => [item.row.Month6]))),
        Math.abs(Math.min.apply(Math, this.benchmarkDataDaily.map(item => [item.row.Year1]))),
        Math.abs(Math.max.apply(Math, this.benchmarkDataDaily.map(item => [item.row.Year1]))),
        Math.abs(Math.min.apply(Math, this.benchmarkDataDaily.map(item => [item.row.Year3]))),
        Math.abs(Math.max.apply(Math, this.benchmarkDataDaily.map(item => [item.row.Year3]))),
        Math.abs(Math.min.apply(Math, this.benchmarkDataDaily.map(item => [item.row.Year5]))),
        Math.abs(Math.max.apply(Math, this.benchmarkDataDaily.map(item => [item.row.Year5]))),
      ]);
      minMaxBarGraph = minMaxBarGraph + 5;

      let minMaxScatterPlot = Math.max.apply(Math, [
        // Scatter Plot(Solution)
        Math.abs(Math.min.apply(Math, this.solutionDataMonthly.map(item => [item.row.AnnualisedReturn5Year]))),
        Math.abs(Math.max.apply(Math, this.solutionDataMonthly.map(item => [item.row.AnnualisedReturn5Year]))),
        Math.abs(Math.min.apply(Math, this.solutionDataMonthly.map(item => [item.row.StdDev]))),
        Math.abs(Math.max.apply(Math, this.solutionDataMonthly.map(item => [item.row.StdDev]))),

        // Benchmark
        Math.abs(Math.min.apply(Math, this.solutionDataMonthly.map(item => [item.row.Benchmark.AnnualisedReturn5Year]))),
        Math.abs(Math.max.apply(Math, this.solutionDataMonthly.map(item => [item.row.Benchmark.AnnualisedReturn5Year]))),
        Math.abs(Math.min.apply(Math, this.solutionDataMonthly.map(item => [item.row.Benchmark.StdDev]))),
        Math.abs(Math.max.apply(Math, this.solutionDataMonthly.map(item => [item.row.Benchmark.StdDev]))),
      ]);
      minMaxScatterPlot = minMaxScatterPlot + 5;
          

      this.chartOptions = Object.assign({}, this.chartOptions, {
          yaxis: {
            ...this.chartOptions.yaxis,
            categories: this.solutionDataDaily.map(item => item.row.Description.trim()),
            max: minMaxBarGraph
          }
        });

      this.scatterChartOptions = {
        ...this.scatterChartOptions,
        xaxis: {
          ...this.scatterChartOptions.xaxis,
          min: -minMaxScatterPlot,
          max: minMaxScatterPlot
        },
        yaxis: {
          ...this.scatterChartOptions.yaxis,
          min: -minMaxScatterPlot,
          max: minMaxScatterPlot
        }
      }; 
    },

    computed: {
      solutionDescription() {
        const ISIN = this.$route.params.ISIN;
        let objectiveDescription = null;
        let strategyDescription = null;
        
        switch(ISIN) {
          // Income 
          case 'IQ2065':
            objectiveDescription = "The Income Solution is suitable for an investor with a conservative appetite for risk and a need to protect principal and maximise income. The solution favours protecting capital over seeking capital appreciation. It aims to outperform the ASISA MA Income peer group average over the short to medium term (2+ years).";
            strategyDescription = "The strategy follows a multi-manager approach and has a flexible mandate with regard to region, style and currency. STRATEGIQ blends best-in-class active managers with cost-effective passive funds to achieve the stated objectives. These underlying funds (which are primarily money market and flexible income funds) invest in a spectrum of investments in the equity, bond, money, or property markets.";
            break;

          // Cautious
          case 'IQ2066':
            objectiveDescription = "The Cautious Solution is suitable for an investor with a conservative appetite for risk and a need to generate income and capital growth over the medium to long term. It aims to outperform the ASISA SA MA Low Equity peer group average over the medium term (3+ years).";
            strategyDescription = "The strategy follows a multi-manager approach and has a flexible mandate with regard to region, style and currency. STRATEGIQ blends best-in-class active managers with cost-effective passive funds to achieve the stated objectives. These underlying funds invest in a spectrum of investments in the equity, bond, money, or property markets.";
            break;
          
          // Balanced 
          case 'IQ2064':
            objectiveDescription = "The Balanced Solution is suitable for an investor with a moderate appetite for risk and a need to generate income and capital growth over the medium to long term. It aims to outperform the ASISA SA MA High Equity peer group average over the long term (5+ years).";
            strategyDescription = "The strategy follows a multi-manager approach and has a flexible mandate with regard to region, style and currency. STRATEGIQ blends best-in-class active managers with cost-effective passive funds to achieve the stated objectives. These underlying funds invest in a spectrum of investments in the equity, bond, money, or property markets.";
            break;

          // Multi-Hedge
          case 'IQ2593':
            objectiveDescription = "The Multi-Hedge Fund Solution is suitable for an investor with a moderate to high appetite for risk and a need to generate income and capital growth over the medium to long term. The portfolio aims to preserve capital by not producing a negative return over a 36-month period while maintaining a low correlation with the JSE All-Share Index and the All-Bond Index.";
            strategyDescription = "The objectives are achieved through diversifying SA hedge fund managers across various strategies and asset classes, including equity long/short, equity market neutral and fixed income hedge funds. These underlying funds invest in a spectrum of investments in the equity, bond, money, or property markets and may apply leverage.";
            break;

          // Global Feeder
          case 'IQ2158':
            objectiveDescription = "The Global Feeder Solution is suitable for an investor with a moderate appetite for risk and a need to generate income and capital growth over the medium to long term. It aims to outperform the ASISA Global MA High Equity peer group average over the long term (5+ years).";
            strategyDescription = "The strategy follows a multi-manager approach and has a flexible mandate with regard to region, style and currency. STRATEGIQ blends best-in-class active managers with cost-effective passive funds to achieve the stated objectives. These underlying funds invest in a spectrum of investments in the equity, bond, money, or property markets.";
            break;

          // Growth
          case 'IQ2186':
            objectiveDescription = "The Growth Solution is suitable for an investor with a high appetite for risk and a need to generate capital growth over the long term. It aims to outperform the ASISA Global Equity General peer group average over the long term (7+ years).";
            strategyDescription = "The strategy follows a multi-manager approach and has a flexible mandate with regard to region, style and currency. STRATEGIQ blends best-in-class active managers with cost-effective passive funds to achieve the stated objectives. These underlying funds invest in a spectrum of investments in the equity, money, or property markets.";
            break;

          // TFSA
          case 'IQ2578':
            objectiveDescription = "The TFSA Growth Solution is suitable for an investor with a high appetite for risk and a need to generate capital growth over the long term. It aims to outperform the ASISA Global Equity General peer group average over the long term (7+ years).";
            strategyDescription = "The strategy follows a multi-manager approach and has a flexible mandate with regard to region, style and currency. STRATEGIQ blends best-in-class active managers with cost-effective passive funds to achieve the stated objectives. These underlying funds invest in a spectrum of investments in the equity, money, or property markets. ";
            break;    
          
          // Global Cautious
          case 'IQ2643':
            objectiveDescription = "The Global Cautious Solution is suitable for an investor with a conservative appetite for risk and a need to generate income and capital growth over the medium to long term. It aims to outperform the ASISA Global MA Low Equity peer group average over the medium term (3+ years).";
            strategyDescription = "The strategy follows a multi-manager approach and has a flexible mandate with regard to region, style and currency. STRATEGIQ blends best-in-class active managers with cost effective passive funds to achieve the stated objectives. These underlying funds invest in a spectrum of investments in the equity, bond, money, or property markets.";
            break; 

          // Global Balanced
          case 'IQ2595':
            objectiveDescription = "The Global Balanced Solution is suitable for an investor with a moderate appetite for risk and a need to generate income and capital growth over the medium to long term. It aims to outperform the ASISA Global MA High Equity peer group average over the long term (5+ years).";
            strategyDescription = "The strategy follows a multi-manager approach and has a flexible mandate with regard to region, style and currency. STRATEGIQ blends best-in-class active managers with cost-effective passive funds to achieve the stated objectives. These underlying funds invest in a spectrum of investments in the equity, bond, money, or property markets.";
            break; 
          
          // Global Growth
          case 'IQ2581':
            objectiveDescription = "The Global Growth Solution is suitable for an investor with a moderate to high appetite for risk and a need to generate income and capital growth over the long term. It aims to outperform the ASISA Global Equity General peer group average over the long term (7+ years).";
            strategyDescription = "The strategy follows a multi-manager approach and has a flexible mandate with regard to region, style and currency. STRATEGIQ blends best-in-class active managers with cost-effective passive funds to achieve the stated objectives. These underlying funds invest in a spectrum of investments in the equity, money, or property markets.";
            break;   
        }
        return {objectiveDescription, strategyDescription};
      }  
    },
    
    data() {
      return {
        errorMessage: "",
        solutionDataDaily: null,
        solutionDataMonthly: null,
        benchmarkDataDaily: null,
        benchmarkDataMonthly: null,
        series: [],
        scatterSeries: [],
        objectiveDescription: null,
        strategyDescription: null,
        // Bar Graph        
          chartOptions: {
            chart: {
              type: 'bar',
              height: 350,
              toolbar: {
                show: false,
              }
            },
            legend: {
              show: true,
              onItemClick: {
                toggleDataSeries: false
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                dataLabels: {
                    position: 'top'
                  },
                columnWidth: '75%',
                endingShape: 'rounded'
              },
            },
            colors: ['#002147', '#3CD7D9'],

            dataLabels: {
                enabled: true,
                style: {
                  colors: ['#002147'],
                  fontFamily: 'Poppins',                  
                },                
                offsetY: -20,

              },
            stroke: {
              show: true,
              width: 6,
              colors: ['transparent']
            },
            yaxis: {
              tickAmount: 5,
              labels: {
                formatter: function (val) {
                  return val.toFixed(0);
                }
              },
              forceNiceScale: true,
            },
            xaxis: {
              categories: ['Inception', '1 Month', '3 Month', '6 Month', '1 Year', '3 Year', '5 Year'],
            },
            fill: {
              opacity: 1,
              colors: ['#002147', '#3CD7D9']
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val
                }
              }
            }
          },


          // Scatter Plot          
          scatterChartOptions: {
            chart: {
              height: 350,
              type: 'scatter',
              zoom: {
                enabled: false,
                type: 'xy'
              },
              toolbar: {
                show: false,
              }
            },
            legend: {
              show: false,
            },
            markers: {
              size: 10,
              colors: ['#002147', '#3CD7D9']
            },
            fill: {
              opacity: 1,
              colors: ['#002147', '#3CD7D9']
            },
            dataLabels: {
              formatter: function(val, opts) {
                return opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]
              },
              offsetY: 1,
              style: {
                fontSize: '10px',
                fontFamily: 'Poppins',
                colors: ['#fff'],  // Change text color as needed.
              },
              background: {
                enabled: false  // Disables the background.
              },
              dropShadow: {
                enabled: false  // Disables the white border.
              }
            },
            xaxis: {
              title: {
                text: 'Standard Deviation',
                style: {
                  fontSize: '14px',
                  fontFamily: 'Poppins',
                  fontWeight: 'medium',
                  color: '#002147'
                },
              },
              tickAmount: 6,
              labels: {
                formatter: function(val) {
                  return parseFloat(val).toFixed(0);
                }
              }
            },
            yaxis: {
              title: {
                text: 'Annualised Return',
                offsetX: 0,
                offsetY: 10,
                style: {
                  fontSize: '14px',
                  fontFamily: 'Poppins',
                  fontWeight: 'medium',
                  color: '#002147'
                },
              },
              tickAmount: 7,
              labels: {
                formatter: function(val) {
                  return parseFloat(val).toFixed(0);
                }
              }
            },
            tooltip: {
              enabled: true,
              y: {
                formatter: function (val) {
                  return val
                }
              },
              x: {
                formatter: function (val) {
                  return val
                }
              }
            }
          },
 
      }
    }
  };
</script>