<template>
  <div class="flex flex-col justify-between h-screen">
    <NavBar/>

    <div class="flex flex-col font-poppins mt-8">
      <div class="font-extrabold px-12">
        <div class="flex">
          <div @click="toggleSolution('SA')" class="bg-[#3CD7D9] text-dark-blue px-4 py-2 flex items-center justify-center cursor-pointer">
            SA SOLUTIONS
          </div>
          <div @click="toggleSolution('Global')" class="bg-light-blue text-dark-blue px-4 py-2 flex items-center justify-center cursor-pointer">
            GLOBAL SOLUTIONS
          </div>
          <div @click="toggleSolution('Structured')" class="bg-light-gold text-dark-blue px-4 py-2 flex items-center justify-center cursor-pointer">
            STRUCTURED SOLUTIONS
          </div>
          <div @click="toggleSolution('Market')" class="bg-dark-blue font-normal text-white px-4 py-2 flex items-center justify-center cursor-pointer">
            MARKET MOVES
          </div>
        </div>
      </div>
      
      <div class="flex flex-col py-8 px-12 md:flex-row md:space-y-0 w-full">
        <div class="flex-1">
          <div class="flex flex-col">
            <div class="flex flex-col">
              <p class="text-dark-blue text-md font-base font-poppins pr-6">Use our powerful tracking software to monitor
                your solutions in real-time, including performance, asset allocation, underlying holdings, and more.
                This allows you and your clients to assess our solutions all in one place.</p>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div class="w-full">
            <p class="text-dark-blue font-base text-md font-poppins">We believe in full transparency in everything
              we do. View our solutions in real-time. Click on the solution and learn more or download latest fund fact
              sheets, performance & attribution and more from the drop-downs above.</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="currentSolution === 'Structured'" class="flex flex-col md:flex-row justify-center items-center gap-8 px-12 py-8">
      <!-- JPEG 1 -->
      <div class="flex-1 p-6 flex flex-col items-center cursor-pointer" @click="fetchPDFLink('PROTECTEDNOTE')">
        <img v-if="uploadedJPEG1Url" :src="uploadedJPEG1Url" alt="JPEG1" class="w-full h-96 object-contain" />
        <p v-else class="text-dark-blue font-semibold">No Image Uploaded</p>
        <a @click="fetchPDFLink('PROTECTEDNOTE')" class="text-dark-blue font-semibold mt-4" href="#">Click to Learn More</a>
      </div>

      <!-- JPEG 2 -->
      <div class="flex-1 p-6 flex flex-col items-center cursor-pointer" @click="fetchPDFLink('AUTOCALLNOTE')">
        <img v-if="uploadedJPEG2Url" :src="uploadedJPEG2Url" alt="JPEG2" class="w-full h-96 object-contain" />
        <p v-else class="text-dark-blue font-semibold">No Image Uploaded</p>
        <a @click="fetchPDFLink('AUTOCALLNOTE')" class="text-dark-blue font-semibold mt-4" href="#">Click to Learn More</a>
      </div>
    </div>

    <div v-if="currentSolution === 'Market'" class="overflow-y-auto w-full xl:ml-48" style="max-height: 500px;">
      <div>
        <div id="chart1" v-if="!errorMessage">
          <apexchart type="bar" height="350" width="80%" :options="globalStockMarketReturnsChartOptions" :series="globalStockMarketReturnsSeries"></apexchart>
        </div>
      </div>

      <div>
        <div id="chart2" v-if="!errorMessage">
          <apexchart type="bar" height="350" width="80%" :options="globalAssetClassReturnsUSDChartOptions" :series="globalAssetClassReturnsUSDSeries"></apexchart>
        </div>
      </div>

      <div>
        <div id="chart3" v-if="!errorMessage">
          <apexchart type="bar" height="350" width="80%" :options="assetClassReturnsZARChartOptions" :series="assetClassReturnsZARSeries"></apexchart>
        </div>
      </div>
    </div>

    <div v-if="showContent && currentSolution !== 'Structured'" class="flex flex-col-reverse md:flex-row gap-y-4 gap-x-16 mb-12"> 
      <div class="w-full md:w-4/12 bg-dark-blue">
        <div class="p-10 md:text-base md:justify-center 2xl:flex 2xl:flex-row 2xl:items-center 2xl:h-full">
          <!-- SA Solutions & Global Solutions Content -->
          <div class="bg-dark-blue text-white">
            <div class="flex flex-col space-y-4 font-poppins">
              <div class="text-light-blue">
                <p class="sm:text-sm md:text-xl lg:text-2xl text-3xl">
                  {{ currentSolutions.title }}
                </p>
              </div>
              <div class="flex flex-col text-white">
                <a v-for="link in currentSolutions.links" :href="link.url" :key="link.text">
                  &bull; {{ link.text }}
                </a>
                <div v-for="section in currentSolutions.extraSections" :key="section.title">
                  <p class="text-light-blue mt-4">{{ section.title }}</p>
                  <div v-for="link in section.links" :key="link.text" class="block">
                    <a :href="link.url" class="block">&bull; {{ link.text }}</a>
                  </div>
                </div>

              </div>
              <div class="border-t border-light-blue w-full"></div>
              <p class="text-light-blue font-normal">Click on solution to view</p>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full md:w-8/12 flex justify-center -mt-8">
        <div v-if="showSASolutions" class="w-full bg-white p-8 relative">
          <div class="flex flex-col items-center space-y-4">
            <div class="flex w-full">
              <!-- LOW -->
              <div class="flex items-center justify-center font-semibold bg-dark-blue text-white w-1/5 h-10 relative">
                <p>LOW</p>
                <div class="absolute right-[-10px] top-0 h-full w-[20px] bg-dark-blue clip-arrow z-10"></div>
              </div>

              <!-- LOW-MODERATE -->
              <div class="flex items-center justify-center font-semibold bg-[#3CD7D9] text-dark-blue w-1/5 h-10 relative">
                <p>LOW-MODERATE</p>
                <div class="absolute right-[-10px] top-0 h-full w-[20px] bg-[#3CD7D9] clip-arrow z-10"></div>
              </div>

              <!-- MODERATE -->
              <div class="flex items-center justify-center font-semibold bg-light-blue text-dark-blue w-1/5 h-10 relative">
                <p>MODERATE</p>
                <div class="absolute right-[-10px] top-0 h-full w-[20px] bg-light-blue clip-arrow z-10"></div>
              </div>

              <!-- MODERATE-HIGH -->
              <div class="flex items-center justify-center font-semibold bg-light-gold text-white w-1/5 h-10 relative">
                <p>MODERATE-HIGH</p>
                <div class="absolute right-[-10px] top-0 h-full w-[20px] bg-light-gold clip-arrow z-10"></div>
              </div>

              <!-- HIGH -->
              <div class="flex items-center font-semibold justify-center bg-dark-blue text-white w-1/5 h-10 relative">
                <p>HIGH</p>
                <div class="absolute right-[-10px] top-0 h-full w-[20px] bg-dark-blue clip-arrow z-10"></div>
              </div>
            </div>
          </div>

          <div class="bg-dark-blue w-8 h-[312px] mt-2 absolute -left-2 flex items-end justify-center">
            <p class="text-white font-semibold text-sm [writing-mode:vertical-rl] [text-orientation:mixed] rotate-180 mb-16 uppercase">
              Expected Return
            </p>
          </div>

          <div class="bg-light-blue w-8 h-[312px] mt-2 absolute flex items-end justify-center">
            <p class="text-dark-blue font-semibold text-sm [writing-mode:vertical-rl] [text-orientation:mixed] rotate-180 mb-16">
              {{ riskProfitedSolutionsTitle }}
            </p>
          </div>

        <!-- Graph Content -->
          <div class="relative w-full h-64 mt-8">
            <div class="absolute left-[19.8%] -bottom-2 h-[270px] border-l-2 border-dotted border-gold"></div>
            <div class="absolute left-[40%] -bottom-2 h-[270px] border-l-2 border-dotted border-gold"></div>
            <div class="absolute left-[60%] -bottom-2 h-[270px] border-l-2 border-dotted border-gold"></div>
            <div class="absolute left-[80%] -bottom-2 h-[270px] border-l-2 border-dotted border-gold"></div>
            <!-- Solutions Points -->
            <div
              :class="{'absolute left-14 bottom-6 flex flex-col items-center': true, 'w-44 absolute left-8': currentSolution === 'Global'}"
            >
              <div class="w-4 h-4 bg-gold rounded-full"></div>
              <a
                :href="currentSolution === 'Global' ? '/IQ910/US/1' : '/IQ2065/SA/1'"
                :class="{'font-semibold text-dark-blue mt-2' : true, 'ml-6': currentSolution === 'Global'} "
              >
                {{ currentSolution === 'Global' ? 'Global Defensive Income Solution' : 'Income Solution' }}
              </a>
            </div>

            <div 
              :class="{'absolute left-[25%] bottom-32 flex flex-col items-center': true, 'w-44 absolute bottom-8 left-[21.9%]': currentSolution === 'Global'}"
            >
              <div class="w-4 h-4 mr-6 bg-gold rounded-full"></div>
              <a :href="currentSolution === 'Global' ? '/IQ2643/US/1' : '/IQ2066/SA/1'" 
                 :class="{'font-semibold text-dark-blue mt-2': true, 'ml-4': currentSolution === 'Global'}"
              >
                {{ currentSolution === 'Global' ? 'Global Cautious Solution' : 'Cautious Solution' }}
              </a>
              <a v-if="currentSolution === 'Global'" href="/IQ937/US/1" class="font-semibold text-dark-blue mt-4 ml-4">
                Global Cautious Passive Solution
              </a>
            </div>

            <div 
              :class="{'absolute left-[44%] bottom-40 flex flex-col items-center': true, 'w-48 absolute bottom-[80px] left-[40.9%]': currentSolution === 'Global'}"
              >
              <div class="w-4 h-4 mr-6 bg-gold rounded-full"></div>
              <a :href="currentSolution === 'Global' ? '/IQGMOD/US/1' : '/IQMOD/SA/1'" class="font-semibold text-dark-blue mt-2 ml-3">
                {{ currentSolution === 'Global' ? 'Global Moderate Solution' : 'Moderate Solution' }}
              </a>
              <a v-if="currentSolution === 'Global'" href="/IQ955/US/1" class="font-semibold text-dark-blue mt-4 ml-3">
                Global Liquid Alternatives Solution
              </a>
            </div>

            <div 
              :class="{'absolute left-[61.5%] bottom-[65px] flex flex-col items-center': true, 'w-40 absolute bottom-[92px] left-[62%]': currentSolution === 'Global'}"
              >
              <div class="w-4 h-4 mr-2 bg-gold rounded-full"></div>
              <a :href="currentSolution === 'Global' ? '/IQ2595/US/1' : '/IQ2593/SA/1'" class="text-dark-blue font-semibold mt-4 ml-4">
                {{ currentSolution === 'Global' ? 'Global Balanced Solution' : 'Multi-Hedge Solution' }}
              </a>
              <a v-if="currentSolution === 'Global'" href="/IQGBAL/US/1" class="text-dark-blue font-semibold mt-3 ml-4">
                Global Balanced Passive Solution
              </a>

              <a v-if="currentSolution !== 'Global'" href="/IQ2064/SA/1" class="text-dark-blue font-semibold mt-3 text-center">Balanced Solution</a>
              <a v-if="currentSolution !== 'Global'" href="/IQ2158/SA/1" class="text-dark-blue font-semibold mt-3 text-center">Feeder Solution</a>
              <a v-if="currentSolution !== 'Global'" href="/IQ2809/SA/1" class="text-dark-blue font-semibold mt-3 text-center">Flexible Solution</a>
            </div>

            <div 
              :class="{'absolute right-10 top-[16px] flex flex-col items-center': true, 'w-40 absolute': currentSolution === 'Global'}"
              >
              <div :class="{'w-4 h-4 bg-gold rounded-full': true, 'ml-8': currentSolution === 'Global'}"></div>
              <a :href="currentSolution === 'Global' ? '/IQ2581/US/1' : '/IQ2186/SA/1'" class="text-dark-blue font-semibold mt-4 text-center">
                {{ currentSolution === 'Global' ? 'Global Growth Solution' : 'Growth Solution' }}
              </a>
              <a v-if="currentSolution !== 'Global'" href="/IQ2578/SA/1" class="text-dark-blue font-semibold mt-2 text-center">TFSA Solution</a>
            </div>
          </div>
          

          <div class="text-center mt-8 text-sm font-semibold text-white bg-dark-blue py-1.5">
            EXPECTED RISK (VOLATILITY)
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<style scoped>
.clip-arrow-up {
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
}
.clip-arrow {
  clip-path: polygon(0 0, 100% 50%, 0 100%);
}
</style>

<script>
import NavBar from '../components/Atomic/AQuarks/NavBar.vue';
import Footer from '../components/Atomic/AQuarks/Footer.vue'
import axios from '../axiosConfig';

export default {
  name: 'ManagerAdmin',
  components: {
    NavBar,
    Footer,
  },

  methods: {
    async fetchPDFLink(isin) {
      try {
        const response = await axios.get(`/File?filetype=pdf&isin=${isin}`);
        const pdfLink = response.data;

        if (pdfLink) {
          window.open(pdfLink, '_blank'); // Open the PDF in a new tab, allowing the user to download it
        } else {
          console.error("PDF link not found for ISIN:", isin);
        }
      } catch (error) {
        console.error("Error fetching PDF:", error);
      }
    },

    async fetchJPEGs() {
      try {
          // Fetch JPEG 1 using its unique ISIN
          const response1 = await axios.get(`/File?filetype=jpeg&isin=image1`);
          console.log("Image1", response1)
          this.uploadedJPEG1Url = response1.data || "";
          if (!this.uploadedJPEG1Url) console.warn("JPEG1 not found");

          // Fetch JPEG 2 using its unique ISIN
          const response2 = await axios.get(`/File?filetype=jpeg&isin=image2`);
          console.log("Image2", response2)
          this.uploadedJPEG2Url = response2.data || "";
          if (!this.uploadedJPEG2Url) console.warn("JPEG2 not found");

      } catch (error) {
          console.error("Error fetching JPEGs:", error);
      }
    },

    computeMinAndSetOptions(data, chartOptions, categoryMapping) {
      let minBarGraph = Math.min(
        ...data.map(item => Math.min(item.row.YearToDate, item.row.Month3, item.row.Year1))
      );
      let maxBarGraph = Math.max(
        ...data.map(item => Math.max(item.row.YearToDate, item.row.Month3, item.row.Year1))
      );

      minBarGraph -= 30; // Decrease min by 30
      maxBarGraph += 50; // Increase max by 50

      return {
        ...chartOptions,
        yaxis: {
          ...chartOptions.yaxis,
          min: minBarGraph,
          max: maxBarGraph,
          categories: data.map(item => {
            const isin = item.row.Isin;
            return categoryMapping[isin] || item.row.Description.trim();
          }),
        },
      };
    },

    toggleSolution(type) {
      this.currentSolution = type;
      this.showContent = type !== "Structured" && type !== "Market";
    }
  },

  mounted() {
    this.fetchJPEGs();
  },

  async created() {
    const stockMarketResponse = await axios.get(`/File?filetype=stockMarket`)
    const zarDailyResponse = await axios.get(`/File?filetype=zarDaily`)
    const usdDailyResponse = await axios.get(`/File?filetype=usdDaily`)

    if (!stockMarketResponse.data || !zarDailyResponse.data || !usdDailyResponse.data) {
      this.errorMessage = "No data is available at the moment.";
      return;
    }

    const parsedStockMarketData = stockMarketResponse.data;
    const parsedZarDailyData = zarDailyResponse.data;
    const parsedUsdDailyData = usdDailyResponse.data;

    const stockMarketCategoryMapping = {
      'DJSMR': 'Dow Jones',
      'SPSMR': 'S&P 500',
      'NASDAQSMR': 'Nasdaq',
      'STOXXSMR': 'Euro Stoxx 50',
      'FTSESMR': 'FTSE',
      'NIKKEISMR': 'Nikkei 225',
      'HSISMR': 'Hang Seng',
      'ALLSRSMR': 'All Share Index',
    }

    const usdDailyCategoryMapping = {
      'MSCIWUSD': 'MSCI World NR USD',
      'MSCIWGUSD': ['MSCI World', 'Growth NR USD'],
      'MSCIWQUSD': ['MSCI World', 'Quality NR USD'],
      'MSCIWVUSD': ['MSCI World', 'Value NR USD'],
      'MSCIEMUSD': ['MSCI EM NR', 'USD'],
      'WGBIUSD': 'FTSE W BIG USD',
      'BGAUSD': ['Bloomberg Global', 'Agg Corp USD TR', 'USD'],
      'GSCIUSD': 'S&P GSCI TR USD',
      'ICE3USD': 'SOFR',
    }

    const zarDailyCategoryMapping = {
      'MSCIWZAR': ['MSCI World NR', 'USD'],
      'FTSEALLZAR': ['FTSE/JSE All', 'Share TR ZAR'],
      'FTSERESZAR': ['FTSE/JSE', 'Resources 10', 'TR ZAR'],
      'FTSEINDZAR': ['FTSE/JSE', 'Industrials 25', 'TR ZAR'],
      'FTSEFINZAR': ['FTSE/JSE', 'Financial 15', 'TR ZAR'],
      'FTSEPROPZAR': ['FTSE/JSE', 'All Property', 'TR ZAR'],
      'FTSEGOVZAR': ['FTSE/JSE ALB', 'GOV TR ZAR'],
      'BM2065': ['ASISA' ,'South Africa', 'MA Income'],
      'BM2593': 'STEFI Composite',
      'ZAE000162608': 'USDZAR',
    }

    this.stockMarketIsin = parsedStockMarketData.filter(item => stockMarketCategoryMapping[item.row.Isin]);
    this.usdDailyIsin = parsedUsdDailyData.filter(item => usdDailyCategoryMapping[item.row.Isin]);
    this.zarDailyIsin = parsedZarDailyData.filter(item => zarDailyCategoryMapping[item.row.Isin]);

    function replaceMissingDataWithZero(dataArray) {
      return dataArray.map(item => item || 0);
    }

    function sortDataByCategoryMapping(dataArray, categoryMapping) {
      const categoryOrder = Object.keys(categoryMapping);
      return dataArray.sort((a, b) => {
        let categoryAIndex = categoryOrder.indexOf(a.row.Isin);
        let categoryBIndex = categoryOrder.indexOf(b.row.Isin);
        return categoryAIndex - categoryBIndex;
      });
    }

    this.stockMarketIsin = sortDataByCategoryMapping(this.stockMarketIsin, stockMarketCategoryMapping);
    this.usdDailyIsin = sortDataByCategoryMapping(this.usdDailyIsin, usdDailyCategoryMapping);
    this.zarDailyIsin = sortDataByCategoryMapping(this.zarDailyIsin, zarDailyCategoryMapping);

    // Stock Market
    this.globalStockMarketReturnsSeries = [
      {
        name: 'YTD',
        data: replaceMissingDataWithZero(this.stockMarketIsin.map(item => item.row.YearToDate))
      },
      {
        name: '3 Months',
        data: replaceMissingDataWithZero(this.stockMarketIsin.map(item => item.row.Month3))
      },
      {
        name: '1 Year',
        data: replaceMissingDataWithZero(this.stockMarketIsin.map(item => item.row.Year1))
      }
    ]
    
    // USD Daily
    this.globalAssetClassReturnsUSDSeries = [
      {
        name: 'YTD',
        data: replaceMissingDataWithZero(this.usdDailyIsin.map(item => item.row.YearToDate))
      },
      {
        name: '3 Months',
        data: replaceMissingDataWithZero(this.usdDailyIsin.map(item => item.row.Month3))
      },
      {
         name: '1 Year',
        data: replaceMissingDataWithZero(this.usdDailyIsin.map(item => item.row.Year1))
      },
    ]

    // ZAR Daily
    this.assetClassReturnsZARSeries = [
      {
        name: 'YTD',
        data: replaceMissingDataWithZero(this.zarDailyIsin.map(item => item.row.YearToDate))
      },
      {
        name: '3 Months',
        data: replaceMissingDataWithZero(this.zarDailyIsin.map(item => item.row.Month3))
      },
      {
        name: '1 Year',
        data: replaceMissingDataWithZero(this.zarDailyIsin.map(item => item.row.Year1))
      },
    ]

    // Stock Market Returns
    this.globalStockMarketReturnsChartOptions = this.computeMinAndSetOptions(
      this.stockMarketIsin,
      this.globalStockMarketReturnsChartOptions,
      stockMarketCategoryMapping
    );

    this.globalStockMarketReturnsChartOptions.xaxis.categories = this.stockMarketIsin.map(item => {
      const isin = item.row.Isin;
      return stockMarketCategoryMapping[isin] || item.row.Description.trim();
    });


    // USD Daily Returns
    this.globalAssetClassReturnsUSDChartOptions = this.computeMinAndSetOptions(
      this.usdDailyIsin,
      this.globalAssetClassReturnsUSDChartOptions,
      usdDailyCategoryMapping
    );

    this.globalAssetClassReturnsUSDChartOptions.xaxis.categories = this.usdDailyIsin.map(item => {
      const isin = item.row.Isin;
      return usdDailyCategoryMapping[isin] || item.row.Description.trim();
    });


    // ZAR Daily Returns
    this.assetClassReturnsZARChartOptions = this.computeMinAndSetOptions(
      this.zarDailyIsin,
      this.assetClassReturnsZARChartOptions,
      zarDailyCategoryMapping
    );

    this.assetClassReturnsZARChartOptions.xaxis.categories = this.zarDailyIsin.map(item => {
      const isin = item.row.Isin;
      return zarDailyCategoryMapping[isin] || item.row.Description.trim();
    });
  },
  

  data() {
      return {
        uploadedPNGUrl: '',
        errorMessage: "",
        stockMarketIsin: [],
        usdDailyIsin: [],
        zarDailyIsin: [],
        globalStockMarketReturnsSeries: [],
        globalAssetClassReturnsUSDSeries: [],
        assetClassReturnsZARSeries: [],

        showSASolutions: true,
        showMarketMoves: false,
        showGlobalSolutions: false,
        showStructuredSolutions: false,

        currentSolution: "SA",
        showContent: true,

        uploadedJPEG1Url: "",
        uploadedJPEG2Url: "",
 
        // Global Stock Market Returns 
        globalStockMarketReturnsChartOptions: {
          chart: {
            type: 'bar',
            height: 400,
            toolbar: {
              show: false,
            }
          },
          title: {
            text: 'Global Stock Market Returns',
            align: 'center',
            style: {
              fontSize: '16px',
              fontWeight: 'bold',
              fontFamily: 'Poppins',
              color: '#002147'
            }
          },
          legend: {
            height: 25,
            offsetY: 10,
            show: true,
            onItemClick: {
              toggleDataSeries: false
            },
          },
          colors: ['#A17D24', '#002147', '#3CD7D9'],
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                position: 'top'
              },
              columnWidth: '95%',
              endingShape: 'rounded'
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis:{
              lines: {
                show: false 
              }
            }
          },

          dataLabels: {
            enabled: true,
            style: {
              colors: ['#002147'],
              fontFamily: 'Poppins',       
              fontSize: '10px',          
            },                
            offsetY: -20,
          },
          stroke: {
            show: true,
            colors: ['transparent']
          },
          yaxis: {
            tickAmount: 7,
            forceNiceScale: true,
            max: 150,
            min: -30,
            labels: {
              style: {
                colors: "#FFFFFF"
              }
            }
          },
          xaxis: {
            labels: {
              style: {
                fontSize: '9px',
                fontWeight: 600,
                fontFamily: 'Poppins',
                colors: '#002147',
              },
            },
            tickAmount: 6,
          },
          fill: {
            opacity: 1,
            colors: ['#A17D24', '#002147', '#3CD7D9']
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          }
        },


        // Global Asset Class Returns (USD)
        globalAssetClassReturnsUSDChartOptions: {
          chart: {
            type: 'bar',
            height: 400,
            toolbar: {
              show: false,
            }
          },
          title: {
            margin: 40,
            text: 'Global Asset Class Returns (USD)',
            align: 'center',
            style: {
              fontSize: '16px',
              fontWeight: 'bold',
              fontFamily: 'Poppins',
              color: '#002147',
            }
          },
          legend: {
            height: 25,
            offsetY: 10,
            show: true,
            onItemClick: {
              toggleDataSeries: false
            }
          },
          colors: ['#A17D24', '#002147', '#3CD7D9'],
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                position: 'top'
              },
              columnWidth: '95%',
              endingShape: 'rounded'
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis:{
              lines: {
                show: false 
              }
            }
          },

          dataLabels: {
            enabled: true,
            style: {
              colors: ['#002147'],
              fontFamily: 'Poppins',  
              fontSize: '10px'                
            },                
            offsetY: -20,
          },
          stroke: {
            show: true,
            colors: ['transparent']
          },
          yaxis: {
            tickAmount: 7,
            forceNiceScale: true,
            max: 150,
            min: -30,
            labels: {
              style: {
                colors: "#FFFFFF"
              }
            }
          },
          xaxis: {
            labels: {
              rotate: 0,
              style: {
                fontSize: '9px',
                fontWeight: 600,
                fontFamily: 'Poppins',
                colors: '#002147',
              },
            },
            tickAmount: 6,
          },
          fill: {
            opacity: 1,
            colors: ['#A17D24', '#002147', '#3CD7D9']
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          }
        },


        // SA Asset Class Returns (ZAR)
        assetClassReturnsZARChartOptions: {
          chart: {
            type: 'bar',
            height: 400,
            toolbar: {
              show: false,
            }
          },
          title: {
            margin: 40,
            text: 'SA Asset Class Returns (ZAR)',
            align: 'center',
            style: {
              fontSize: '16px',
              fontWeight: 'bold',
              fontFamily: 'Poppins',
              color: '#002147',
            }
          },
          legend: {
            height: 25,
            offsetY: 10,
            show: true,
            onItemClick: {
              toggleDataSeries: false
            },
          },
          colors: ['#A17D24', '#002147', '#3CD7D9'],
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                position: 'top'
              },
              columnWidth: '95%',
              endingShape: 'rounded'
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis:{
              lines: {
                show: false 
              }
            }
          },

          dataLabels: {
            enabled: true,
            style: {
              colors: ['#002147'],
              fontFamily: 'Poppins',         
              fontSize: '10px'        
            },                
            offsetY: -20,
          },
          stroke: {
            show: true,
            colors: ['transparent']
          },
          yaxis: {
            tickAmount: 7,
            forceNiceScale: true,
            max: 150,
            min: -30,
            labels: {
              style: {
                colors: "#FFFFFF"
              }
            }
          },
          xaxis: {
            labels: {
              rotate: 0,
              style: {
                fontSize: '9px',
                fontWeight: 600,
                fontFamily: 'Poppins',
                colors: '#002147',
              }
            },
            tickAmount: 6,
          },
          fill: {
            opacity: 1,
            colors: ['#A17D24', '#002147', '#3CD7D9']
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          }
        },
 
      }
    },

    computed: {
      riskProfitedSolutionsTitle() {
        return this.currentSolution === "Global"
          ? "Global Risk-Profited Solutions"
          : "Local Risk-Profited Solutions";
      },

    currentSolutions() {
    const solutions = {
      SA: {
        title: "SA Solutions",
        links: [
          { text: "STRATEGIQ Income Solution", url: "/IQ2065/SA/1" },
          { text: "STRATEGIQ Cautious Solution", url: "/IQ2066/SA/1" },
          { text: "STRATEGIQ Moderate Solution", url: "/IQMOD/SA/1" },
          { text: "STRATEGIQ Balanced Solution", url: "/IQ2064/SA/1" },
          { text: "STRATEGIQ Flexible Solution", url: "/IQ2809/SA/1" },
          { text: "STRATEGIQ Growth Solution", url: "/IQ2186/SA/1" },
          { text: "STRATEGIQ TFSA Solution", url: "/IQ2578/SA/1" },
          { text: "STRATEGIQ Multi-Hedge Solution", url: "/IQ2593/SA/1" },       
          { text: "STRATEGIQ Global Feeder Solution", url: "/IQ2158/SA/1" }     
        ],
        extraSections: [
          { 
            title: "Enhanced Pre-Retirement Solution",
            links: [{ text: "STRATEGIQ Enhanced Balanced Solution", url: "/IQ3102/SA/1" }]
          },
          {
            title: "Living Annuity Solutions",
            links: [
              { text: "STRATEGIQ Enhanced Low Income", url: "/IQLAL/SA/1" },
              { text: "STRATEGIQ Enhanced Medium Income", url: "/IQLAM/SA/1" },
              { text: "STRATEGIQ Enhanced High Income", url: "/IQLAH/SA/1" },
            ],
          },
        ],
      },
      Global: {
        title: "Global Solutions",
        links: [
          { text: "STRATEGIQ Global Defensive Income Solution", url: "/IQ910/US/1"},
          { text: "STRATEGIQ Global Cautious Solution", url: "/IQ2643/US/1" },
          { text: "STRATEGIQ Global Cautious Passive Solution", url: "/IQ937/US/1" },
          { text: "STRATEGIQ Global Moderate Solution", url: "/IQGMOD/US/1" },
          { text: "STRATEGIQ Global Balanced Solution", url: "/IQ2595/US/1" },
          { text: "STRATEGIQ Global Balanced Passive Solution", url: "/IQGBAL/US/1" },
          { text: "STRATEGIQ Global Growth Solution", url: "/IQ2581/US/1" },
          { text: "STRATEGIQ Global Liquid Alts Solution", url: "/IQ955/US/1" },
        ],
        extraSections: [
          {
            title: "Enhanced Global Solutions",
            links: [
              { text: "STRATEGIQ Enhanced Protector", url: "/IQGPRO/US/1" },
              { text: "STRATEGIQ Enhanced Accumulator", url: "/IQGACC/US/1" },
            ],
          },
        ],
      },
    };

    // console.log("Current Solution:", this.currentSolution);
    // console.log("Computed Solutions:", solutions[this.currentSolution]);

    return solutions[this.currentSolution] || solutions["SA"];
  },
  },
}
</script>


<style scoped>
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #7C7878;
  border-radius: 4px;
}
</style>