import axios, {AxiosInstance} from 'axios';
import keycloak from './keycloak';

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASEURL,
});

axiosInstance.interceptors.request.use(config => {
    if (keycloak.authenticated) {
        config.headers.Authorization = `Bearer ${keycloak.token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(undefined, async (error) => {
    if (error.config && error.response && error.response.status === 401) {
        if (keycloak.token) {
            keycloak.updateToken(30).then(() => {
                error.config.headers.Authorization = 'Bearer ' + keycloak.token;
                return axiosInstance(error.config);
            }).catch(() => {
                keycloak.login();
            });
        } else {
            keycloak.login();
        }
    }
    return Promise.reject(error);
});

export default axiosInstance;