<template>
  <MainLayout>
  <NavBar />
  <div class="flex flex-col">
    <div class="container mx-auto content-container mt-12 relative">
      <div class="inline-block top-section">
        <h1 class="font-poppins text-3xl text-dark-blue font-bold mt-10 ml-5">{{ managerData && managerData.length > 0 ? managerData[0].row.Description : 'No data'}}</h1>
        <h2 class="font-poppins font-extrabold text-xl text-gold ml-5 mt-7" :class="{ 'pb-10': imageError }">
          <span class="flex">
            Download Manager Research Report 
            <svg class="fill-gold ml-2 mt-1" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
            <img @click="fetchPDFLink()" class="ml-6 -mt-4 z-30" src="../../public/img/pdf_img.png" alt="Pdf Image">
          </span>
        </h2>
      </div>

      <div class="flex justify-end -mt-28 mr-24 logo_img">
        <img class="flex justify-end w-auto h-12" v-if="imageUrl" :src="imageUrl" @error="handleImageError">
      </div>
      
      <div class="flex flex-container">
        <div class="w-full left-side centered">
          <!-- Left Side -->
          <div class="mt-24 w-full">
            <div class="w-[75%] ratings">
              <span class="font-poppins font-medium px-4 py-3 ml-5 bg-dark-blue text-white">
                STRATEGIQ Fund Rating
              </span>
              <span class="font-poppins font-bold text-dark-blue float-right">{{ getLetterRating(managerData && managerData.length > 0 ? managerData[0].row.FundRating : 'No data')}}</span>
              <div class="h-2 mt-1 ml-5 w-full border-b border-dark-blue"></div>
            </div>
          </div>
  
          <div class="mt-5 w-full">
            <div class="w-[75%] ratings">
              <span class="font-poppins font-medium px-4 py-3 ml-5 bg-dark-blue text-white">
                STRATEGIQ Risk Management Rating
              </span>
              <span class="font-poppins font-bold text-dark-blue float-right">{{ managerData && managerData.length > 0 ? managerData[0].row.RiskManagementRating : 'No data'}}</span>
              <div class="h-2 mt-1 ml-5 w-full border-b border-dark-blue"></div>
            </div>
          </div>
  
          <div class="mt-5 w-full">
            <div class="w-[75%] ratings">
              <span class="font-poppins font-medium px-4 py-3 ml-5 bg-dark-blue text-white">
                Morningstar Overall Rating
              </span>
              <span class="font-poppins font-bold text-dark-blue float-right">{{ managerData && managerData.length > 0 && managerData[0].row.MorningStarOverallRating ? managerData[0].row.MorningStarOverallRating + ' Stars' : 'Not Rated' }}</span>
              <div class="h-2 mt-1 ml-5 w-full border-b border-dark-blue"></div>
            </div>
          </div>
  
          <div class="mt-5 w-full">
            <div class="w-[75%] ratings">
              <span class="font-poppins font-medium px-4 py-3 ml-5 bg-dark-blue text-white">
                Manager Classification
              </span>
              <span class="font-poppins font-bold text-dark-blue float-right">{{ managerData && managerData.length > 0 ? managerData[0].row.Classification : 'No data' }}</span>
              <div class="h-2 mt-1 ml-5 w-full border-b border-dark-blue"></div>
            </div>
          </div>
  
          <div class="mt-5 w-full">
            <div class="w-[75%] ratings">
              <span class="font-poppins font-medium px-4 py-3 ml-5 bg-dark-blue text-white">
                Comparable Benchmark
              </span>
              <span class="font-poppins font-bold text-dark-blue float-right">{{ managerData && managerData.length > 0 ? managerData[0].row.Benchmark : 'No data' }}</span>
              <div class="h-2 mt-1 ml-5 w-full border-b border-dark-blue"></div>
            </div>
          </div>
        </div>
        
        <!-- Right Side (Double Bar Graph) -->
        <div class="w-1/2 mt-24 mr-20 right-side">  
          <div class="w-full" id="chart">
            <apexchart class="bar-graph" type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
          </div>
          <table class="font-poppins font-semibold text-xs text-center ml-4 table-responsive">
            <thead class="text-xs uppercase bg-dark-blue text-white">
              <tr>
                <th scope="col" class="px-6 py-3">
                  
                </th>
                <th scope="col" class="px-6 py-3">
                  1 Month
                </th>
                <th scope="col" class="px-6 py-3">
                  3 Month
                </th>
                <th scope="col" class="px-6 py-3">
                  6 Month
                </th>
                <th scope="col" class="px-6 py-3">
                  1 Year
                </th>
                <th scope="col" class="px-6 py-3">
                  3 Year
                </th>
                <th scope="col" class="px-6 py-3">
                  5 Year
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white text-dark-blue">
                <td class="px-6 py-3">
                  {{ solutionDataDaily && solutionDataDaily.length > 0 ? solutionDataDaily[0].row.Description : 'No data' }}
                </td>
                <td class="px-6 py-3">
                  {{ solutionDataDaily && solutionDataDaily.length > 0 ? solutionDataDaily[0].row.Month1 : 'No data' }}
                </td>
                <td class="px-6 py-3">
                  {{ solutionDataDaily && solutionDataDaily.length > 0 ? solutionDataDaily[0].row.Month3 : 'No data' }}
                </td>
                <td class="px-6 py-3">
                  {{ solutionDataDaily && solutionDataDaily.length > 0 ? solutionDataDaily[0].row.Month6 : 'No data' }}
                </td>
                <td class="px-6 py-3">
                  {{ solutionDataDaily && solutionDataDaily.length > 0 ? solutionDataDaily[0].row.Year1 : 'No data'}}
                </td>
                <td class="px-6 py-3">
                  {{ solutionDataDaily && solutionDataDaily.length > 0 ? solutionDataDaily[0].row.Year3: 'No data'}}
                </td>
                <td class="px-6 py-3">
                  {{ solutionDataDaily && solutionDataDaily.length > 0 ? solutionDataDaily[0].row.Year5: 'No data'}}
                </td>
              </tr>              
              <tr class="bg-white border-b-2 border-dark-blue text-dark-blue">
                <td class="px-6 py-3">
                  {{ benchmarkData && benchmarkData.length > 0  && benchmarkData[0]?.row?.Description ? benchmarkData[0].row.Description : 'No data' }}
                </td>
                <td class="px-4 py-3">
                  {{ benchmarkData && benchmarkData.length > 0  && benchmarkData[0]?.row?.Month1 ? benchmarkData[0].row.Month1 : 'No data' }}
                </td>
                <td class="px-4 py-3">
                  {{ benchmarkData && benchmarkData.length > 0  && benchmarkData[0]?.row?.Month3 ? benchmarkData[0].row.Month3 : 'No data' }}
                </td>
                <td class="px-4 py-3">
                  {{ benchmarkData && benchmarkData.length > 0  && benchmarkData[0]?.row?.Month6 ? benchmarkData[0].row.Month6 : 'No data' }}
                </td>
                <td class="px-4 py-3">
                  {{ benchmarkData && benchmarkData.length > 0  && benchmarkData[0]?.row?.Year1 ? benchmarkData[0].row.Year1 : 'No data' }}
                </td>
                <td class="px-4 py-3">
                  {{ benchmarkData && benchmarkData.length > 0  && benchmarkData[0]?.row?.Year3 ? benchmarkData[0].row.Year3 : 'No data' }}
                </td>
                <td class="px-4 py-3">
                  {{ benchmarkData && benchmarkData.length > 0  && benchmarkData[0]?.row?.Year5 ? benchmarkData[0].row.Year5 : 'No data' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="w-full lg:w-[35%] flex justify-start mt-5 absolute top-[460px] left-20 mb-36">
        <div class="w-[90%] max-w-3xl">
          <div class="text-center font-poppins font-bold text-md text-gold mb-4">
            Risk-Return: 3 Years
          </div>
          <div id="scatter-3yr" v-if="scatterSeries.length > 0">
            <apexchart
              type="scatter"
              height="350"
              width="100%"
              :options="scatterChartOptions"
              :series="scatterSeries"
            ></apexchart>
          </div>
        </div>
      </div>
  
      <!-- Donut Charts -->
      <div class="flex mt-44 donut-container">
        <!-- Donut Chart 1 -->
        <div class="mr-5">
          <div class="w-[95%] donut-responsive" id="chart2">
            <apexchart type="donut" :options="Donut1ChartOptions" :series="Donut1Series"></apexchart>
          </div>
          <div class="text-center font-poppins donut-description">
            <label class="font-extrabold text-dark-blue text-3xl">{{ managerData && managerData.length > 0 ? (managerData[0].row.ParentScore/10)*100 + '%': 'No data' }}</label>
            <h3 class="font-bold text-dark-blue mt-2">PARENT</h3>
            <p class="font-medium text-dark-blue text-xs mt-1 leading-5">
              Business overview; ownership & corporate <br>
              structure; investor profile of the fund; <br>
              regular & fund domicile; insurance details.
            </p>
          </div>
        </div>
  
        <!-- Donut Chart 2 -->
        <div class="mr-5">
          <div class="w-[95%] donut-responsive" id="chart3">
            <apexchart type="donut" :options="Donut2ChartOptions" :series="Donut2Series"></apexchart>
          </div>
          <div class="text-center donut-description">
            <label class="font-poppins font-extrabold text-dark-blue text-3xl">{{ managerData && managerData.length > 0 ? (managerData[0].row.PeopleScore/10)*100 + '%': 'No data' }}</label>
            <h3 class="font-poppins font-bold text-dark-blue mt-2">PEOPLE</h3>
            <p class="font-poppins text-dark-blue text-xs mt-1 leading-5">
              Investment team; decision-making process; <br>
              incentive structure; key man risk; operational <br>
              support.
            </p>
          </div>
        </div>
  
        <!-- Donut Chart 3 -->
        <div class="mr-5">
          <div class="w-[95%] donut-responsive" id="chart4">
            <apexchart type="donut" :options="Donut3ChartOptions" :series="Donut3Series"></apexchart>
          </div>
          <div class="text-center donut-description">
            <label class="font-poppins font-extrabold text-dark-blue text-3xl">{{ managerData && managerData.length > 0 ? (managerData[0].row.ProcessScore/10)*100 + '%': 'No data' }}</label>
            <h3 class="font-poppins font-bold text-dark-blue mt-2">PROCESS</h3>
            <p class="font-poppins text-dark-blue text-xs mt-1 leading-5">
              Philosophy & process; portfolio construction; <br>
              security selection; sell discipline; research <br>
              scope/universe; risk management; ESG <br>
              considerations.
            </p>
          </div>
        </div>
  
        <!-- Donut Chart 4 -->
        <div class="mr-5">
          <div class="w-[95%] donut-responsive" id="chart5">
            <apexchart type="donut" :options="Donut4ChartOptions" :series="Donut4Series"></apexchart>
          </div>
          <div class="text-center donut-description">
            <label class="font-poppins font-extrabold text-dark-blue text-3xl">{{ managerData && managerData.length > 0 ? (managerData[0].row.PerformanceScore/10)*100 + '%': 'No data' }}</label>
            <h3 class="font-poppins font-bold text-dark-blue mt-2">PERFORMANCE</h3>
            <p class="font-poppins text-dark-blue text-xs mt-1 leading-5">
              Track record; style classification; positioning; <br>
              scenario analysis; appropriate benchmark <br>
              comparison (risk-return analysis).
            </p>
          </div>
        </div>
  
        <!-- Donut Chart 5 -->
        <div class="">
          <div class="w-[95%] donut-responsive" id="chart6">
            <apexchart type="donut" :options="Donut5ChartOptions" :series="Donut5Series"></apexchart>
          </div>
          <div class="text-center donut-description">
            <label class="font-poppins font-extrabold text-dark-blue text-3xl">{{ managerData && managerData.length > 0 ? (managerData[0].row.PriceScore/10)*100 + '%': 'No data' }}</label>
            <h3 class="font-poppins font-bold text-dark-blue mt-2">PRICE</h3>
            <p class="font-poppins text-dark-blue text-xs mt-1 leading-5">
              Fee class & currency details; section 65 <br>
              approval; feeder fund options; platform <br>
              availability; ISIN code details.
            </p>
          </div>
        </div>
      </div> 
      
      <button @click="onClickNavigatePrev()" class="bg-gradient-to-r from-dark-blue-gradient via-dark-blue-gradient to-light-blue-gradient font-poppins text-white px-4 py-2 my-4 ml-5 block rounded-md">
        <span class="flex items-center">
          <svg class="fill-white mr-3" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
          Underlying Manager Details & Asset Allocation
        </span>
      </button>
    </div>
  </div> 
    <Footer />
    </MainLayout>
  </template>
  
  <style scoped>
  @media screen and (min-width: 1539px) {
    .logo_img {
      display: flex;
      justify-content: flex-end;
      margin-right: 80px;
    }
  }

  @media screen and (max-width: 1539px) {  
    .logo_img {
      margin-right: 95px;
    }

    .flex-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
    }
    .centered {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .centered .ratings {
      width: 92%;
    }
    .left-side {
      width: 100%;
    }
    
    .right-side {
      width: 95%;
    }
    .table-responsive {
      margin-left: auto;
      margin-right: auto;
    }
  
    .donut-container {
      display: flex;
      justify-content: center;
        flex-wrap: wrap;
      }
      .donut-container > div {
        margin-bottom: 20px;
      }
      .donut-responsive {
        width: 100% !important;
      }
      .donut-description p {
        font-size: 13px;
      }
  }

  @media screen and (max-width: 1279px) {
    .logo_img{
      margin-right: 55px;
    }
  }

  @media screen and (max-width: 1023px){
    .logo_img {
      margin-right: 35px;
      margin-top: -135px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .logo_img {
      margin-right: 35px;
      margin-top: -135px;
    }

    .centered {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .centered .ratings {
      width: 92%;
    }
  
    .right-side {
      margin-left: 50px;
    }
    .bar-graph {
      margin-left: auto;
      margin-right: auto;
    }
    .table-responsive {
      margin-left: 20px;
    }
  
    .donut-description p{
      font-size: 13px;
    }
  }
  </style>
  
  <script>
    import NavBar from '../components/Atomic/AQuarks/NavBar.vue';
    import Footer from '../components/Atomic/AQuarks/Footer.vue';
    import { useRouter } from 'vue-router';
    import axios from '../axiosConfig';
    import MainLayout from '../components/Layouts/MainLayout.vue';
  
    export default {
      el: '#app',
      name: 'ManagerPage',
      components: {
        NavBar,
        Footer,
        MainLayout
      },
      setup() {
        const router = useRouter();
        return { router };
      },
      computed: {
        
      },
      methods: {
        getLetterRating(rating) {
          if (rating === "S") {
            return 'A+';
        } else if (rating === null) {
          return 'Not Rated';
        } else {
          return rating;
        }
        },
        onClickNavigatePrev() {
          this.$router.push(`/${this.$route.params.FROMISIN}/${this.$route.params.LOCALE}/3`).then(() => {
            window.scrollTo(0, 0);
          });
        },
        handleImageError() {
          this.imageError = true;
          this.imageUrl = null;
        },
        async fetchPDFLink() {
          try {
            const response = await axios.get(`/File?filetype=pdf&isin=${this.$route.params.ISIN}`);
            const pdfLink = response.data; // retrieves the pdf link from the response data

            window.open(pdfLink, '_blank'); // Redirect the user to the PDF link resulting in a download
          } catch (error) {
            console.error(error)
          }
        }
      },
      
      async created() {
        let locale = ''
        if(this.$route.params.LOCALE == 'SA')
        {
          locale = "zar"
        }
        else{
          locale = "usd"
        }
        let ISIN = this.$route.params.ISIN;
        const dailyZarResponse = await axios.get(`/File?filetype=${locale}Daily`);
        const monthlyResponse = await axios.get(`/File?filetype=${locale}Monthly`);
        const managerResponse = await axios.get(`/File?filetype=managers`);

        try {
          const response = await axios.get(`/File?filetype=png&isin=${ISIN}`);
          this.imageUrl = response.data;
          this.loading = false;
        } catch (error) {
          console.error(error);
          this.loading = false;
        }

        const parsedZarDailyData = dailyZarResponse.data;
        const parsedManagerData = managerResponse.data;

        const parsedMonthlyData = monthlyResponse.data;

        let filteredISINDailyData = parsedZarDailyData.filter(item => item.row.Isin === ISIN);
        let filteredManagerData = parsedManagerData.filter(item => item.row.Isin === ISIN);
        
        this.solutionDataDaily = filteredISINDailyData;
        this.managerData = filteredManagerData;
        
        if (this.managerData && this.managerData.length > 0) {
          let benchmarkName = this.managerData[0].row.Benchmark;
          this.benchmarkData = parsedZarDailyData.filter(item => item.row.Description === benchmarkName);

          // Scatter Plot Logic
        if (this.solutionDataDaily && this.solutionDataDaily.length > 0) {
          const dailyRow = this.solutionDataDaily[0].row;
          const benchmarkRow = this.benchmarkData && this.benchmarkData.length > 0 ? this.benchmarkData[0].row : null;

          this.scatterSeries = [
            {
              name: dailyRow.Description || 'No data',
              data: [
                [
                  dailyRow.Year3StdDev || 0, // X-axis
                  dailyRow.Year3 || 0, // Y-axis
                ],
              ],
            },
            ...(benchmarkRow
              ? [
                  {
                    name: benchmarkRow.Description || 'No data',
                    data: [
                      [
                        benchmarkRow.Year3StdDev || 0, // X-axis
                        benchmarkRow.Year3 || 0, // Y-axis
                      ],
                    ],
                  },
                ]
              : []),
          ];
        }

          this.series = [
            {
              name: this.solutionDataDaily[0]?.row?.Description ?? 'No data',
              data: [
                this.solutionDataDaily[0]?.row?.Month1 ?? 0, 
                this.solutionDataDaily[0]?.row?.Month3 ?? 0, 
                this.solutionDataDaily[0]?.row?.Month6 ?? 0, 
                this.solutionDataDaily[0]?.row?.Year1 ?? 0, 
                this.solutionDataDaily[0]?.row?.Year3 ?? 0, 
                this.solutionDataDaily[0]?.row?.Year5 ?? 0
              ]
            }
          ];

          if(this.benchmarkData) {
            this.series.push({
              name: this.benchmarkData[0]?.row?.Description ?? 'No data',
              data: [
                this.benchmarkData[0]?.row?.Month1 ?? 0,
                this.benchmarkData[0]?.row?.Month3 ?? 0,
                this.benchmarkData[0]?.row?.Month6 ?? 0,
                this.benchmarkData[0]?.row?.Year1 ?? 0,
                this.benchmarkData[0]?.row?.Year3 ?? 0,
                this.benchmarkData[0]?.row?.Year5 ?? 0
              ]
            });
          }
          
          this.Donut1Series = [ 
            this.managerData[0]?.row?.ParentScore ?? 0, 
            10 - (this.managerData[0]?.row?.ParentScore ?? 0)
          ],
          this.Donut2Series = [
            this.managerData[0]?.row?.PeopleScore ?? 0,
            10 - (this.managerData[0]?.row?.PeopleScore ?? 0)
          ],
          this.Donut3Series = [
            this.managerData[0]?.row?.ProcessScore ?? 0,
            10 - (this.managerData[0]?.row?.ProcessScore ?? 0)
          ],
          this.Donut4Series = [
            this.managerData[0]?.row?.PerformanceScore ?? 0,
            10 - (this.managerData[0]?.row?.PerformanceScore ?? 0)
          ],
          this.Donut5Series = [
            this.managerData[0]?.row?.PriceScore ?? 0,
            10 - (this.managerData[0]?.row?.PriceScore ?? 0)
          ]
        }
      },
  
      data() {
        return{
          solutionDataDaily: null,
          benchmarkDataDaily: null,
          morningStarRating: '',
          benchmarkData: null,
          scatterSeries: [],
          managerData: [],
          imageUrl: null,
          imageError: false,
          loading: true,
          series: [],
          Donut1Series: [],
          Donut2Series: [],
          Donut3Series: [],
          Donut4Series: [],
          Donut5Series: [],
  
          // Bar Graph
            chartOptions: {
              chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                  show: false,
                }
              },
              legend: {
                show: true,
                onItemClick: {
                  toggleDataSeries: false
                }
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  dataLabels: {
                    position: 'top'
                  },
                  columnWidth: '75%',
                  endingShape: 'rounded'
                },
              },
              colors: ['#002147', '#3CD7D9'],
  
              dataLabels: {
                enabled: true,
                style: {
                  colors: ['#002147'],
                  fontFamily: 'Poppins',
                },
                offsetY: -20,
              },
              stroke: {
                show: true,
                width: 6,
                colors: ['transparent']
              },
              yaxis: {
                tickAmount: 5,
                labels: {
                  formatter: function (val) {
                    return val.toFixed(0);
                  }
                }
              },
              xaxis: {
                categories: ['1 Month', '3 Month', '6 Month', '1 Year', '3 Years', '5 Years'],
              },
              fill: {
                opacity: 1,
                colors: ['#002147', '#3CD7D9']
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val
                  }
                }
              }
            },

            // Scatter Plot         
            scatterChartOptions: {
              chart: {
                height: 350,
                type: "scatter",
                zoom: {
                  enabled: true,
                  type: "xy",
                },
                toolbar: {
                  show: false,
                },
              },
              colors: ['#002147', '#3CD7D9'],
              legend: {
                show: true,
              },
              markers: {
                size: 8,
                colors: ["#002147", "#3CD7D9"],
              },
              xaxis: {
                title: {
                  text: "Standard Deviation",
                  style: {
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: "medium",
                    color: "#002147",
                  },
                },
                min: 0, 
                max: 20, 
                tickAmount: 5, 
                labels: {
                  formatter: (val) => val.toFixed(1),
                },
              },
              yaxis: {
                title: {
                  text: "Annualised Return",
                  style: {
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: "medium",
                    color: "#002147",
                  },
                },
                min: 0,
                max: 20,
                tickAmount: 5,
              },
              tooltip: {
                enabled: true,
                y: {
                  formatter: function (val) {
                    return `${val}`;
                  },
                },
                x: {
                  formatter: function (val) {
                    return `${val}`;
                  },
                },
              },
            },
            // End Of Scatter Plot
  
            // Donut Chart 1
            Donut1ChartOptions: {
              chart: {
                type: 'donut',
              },
              tooltip: {
                enabled: false
              },
              legend: {
                show: false,
              },
              dataLabels: {
                enabled: false,
              },
              colors: ['#46D7D8', '#ffffff'],
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                }
              }],
            },
  
            // Donut Chart 2
            Donut2ChartOptions: {
              chart: {
                type: 'donut',
              },
              tooltip: {
                enabled: false
              },
              legend: {
                show: false,
              },
              dataLabels: {
                enabled: false
              },
              colors: ['#002147', '#ffffff'],
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                }
              }]
            },
  
            // Donut Chart 3
            Donut3ChartOptions: {
              chart: {
                type: 'donut',
              },
              tooltip: {
                enabled: false
              },
              legend: {
                show: false,
              },
              dataLabels: {
                enabled: false
              },
              colors: ['#A17D24', '#ffffff'],
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                }
              }]
            },
  
            // Donut Chart 4
            Donut4ChartOptions: {
              chart: {
                type: 'donut',
              },
              tooltip: {
                enabled: false
              },
              legend: {
                show: false,
              },
              dataLabels: {
                enabled: false
              },
              colors: ['#A9EDEC', '#ffffff'],
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                }
              }]
            },
  
            // Donut Chart 5
            Donut5ChartOptions: {
              chart: {
                type: 'donut',
              },
              tooltip: {
                enabled: false
              },
              legend: {
                show: false,
              },
              dataLabels: {
                enabled: false
              },
              colors: ['#000000', '#ffffff'],
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                }
              }]
            },
  
  
        }
      }
    }
  </script>